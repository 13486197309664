import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { getPathLastItem, getSelectedProblem, getWorkAnswer, isOperationalView, isProblemSeen, isSubmissionAnswered, isTeacherUser } from "./getters";
import { hideMessage } from "./message";
import { addEditItemViewHidden, addNewUserToClassViewHidden } from "./manage";
import { problemAnswerChanged } from "./work";

export const ITEM_TYPE_CONTAINER = 'container';
export const ITEM_TYPE_CLASS = 'class';
export const ITEM_TYPE_PROBLEM = 'problem';
export const ITEM_TYPE_STUDENT = 'student';
export const ITEM_TYPE_ROOT = 'root';
export const ITEM_TYPE_CONTAINER_OR_PROBLEM = 'container_or_problem'; //if we do not know what is actually in container1

export const POBLEM_TYPE_NO_ANSWER = 'no_answer';
export const POBLEM_TYPE_CHOICE = 'choice';
export const POBLEM_TYPE_TEXT = 'text';

export const NAVIGATION_TYPE_DIRECTION_IN = 'IN';
export const NAVIGATION_TYPE_DIRECTION_OUT = 'OUT';
export const NAVIGATION_TYPE_GOTO = 'GOTO';

export const ROOT_PATH = {id: 1, type:ITEM_TYPE_ROOT, name: 'Classes' }

const slice = createSlice({
    name: 'items',
    initialState: { 
        loading: false,
        loadedItemsType: false,
        list: {},
        // selectedProblemId: null,
        selectedStudentId: null,
        selectedClassId: null,
        itemStatusChangingId: null,
        cleaningContainerSubmissionsId: null,
        path: [],
        // pathDirty: false, //in some cases we change path before calling to server this flug helps to detect when server error occures and roll back the path
    },
    reducers: {

        itemStatusChangingFailed(items, action) {
            items.itemStatusChangingId = null;
        }, 
        itemStatusChangingStarted(items, action) {
            items.itemStatusChangingId = action.payload;
        },

        itemStatusChangingFinished: (items, action) => {
            items.itemStatusChangingId = null;
            const {itemId, disabled} = action.payload;
            items.list[itemId].disabled = disabled;
        },

        containerSubmissionsCleaningFailed(items, action) {
            items.cleaningContainerSubmissionsId = null;
        }, 
        containerSubmissionsCleaningStarted(items, action) {
            items.cleaningContainerSubmissionsId = action.payload;
        },

        containerSubmissionsCleaningFinished: (items, action) => {
            const {itemId} = action.payload;
            items.list[itemId].submissionsCount = 0;
            items.list[itemId].badgeCount = 0;
            items.list[itemId].score = 0;
        },

        toogleSelectedProblemSolutionVisibility(items, action) {
            const selectedProblem = getSelectedProblem(items);
            if(selectedProblem) {
                items.list[selectedProblem.id].solutionVisible = !items.list[selectedProblem.id].solutionVisible;
            }
        },


        toogleSelectedProblemSubmissionLogVisibility(items, action) {
            const selectedProblem = getSelectedProblem(items);
            if(selectedProblem) {
                items.list[selectedProblem.id].submissionLogVisible = !items.list[selectedProblem.id].submissionLogVisible;
            }
        },

        resetItems: (items, action) => {
            items.list = {};
            items.selectedStudentId = null;
            items.selectedClassId = null;
            items.path = [];
            items.cleaningContainerSubmissionsId = null;
            items.itemStatusChangingId = null;
        },

        navigationStarted: (items, action) => {
            const item = action.payload;
            if(items.selectedStudentId) {
                if([ITEM_TYPE_ROOT, ITEM_TYPE_CLASS].includes(item.type)) {
                    items.selectedStudentId = null;
                }
            }
            items.loading = true;
        },

        updateSelectedStudentAndClass(items, action) {
            const item = action.payload;
            if(items.selectedStudentId) {
                if([ITEM_TYPE_ROOT, ITEM_TYPE_CLASS].includes(item.type)) {
                    items.selectedStudentId = null;
                }
            } else {
                if(ITEM_TYPE_STUDENT === item.type) {
                    items.selectedStudentId =  item.id;
                }
            }

            if(items.selectedClassId) {
                if(ITEM_TYPE_ROOT === item.type) {
                    items.selectedClassId = null
                }
            } else {
                if(ITEM_TYPE_CLASS === item.type) {
                    items.selectedClassId = item.id;
                }
            }
        },

        navigationaPathUpdated: (items, action) => {
            
            const { type: navigationType } = action.payload;

            if(navigationType === NAVIGATION_TYPE_GOTO) {
                /**
                 * Go to specified index of path
                 */
                const { item } = action.payload;

                let index = 0;

                items.path.forEach((element, mIndex) => {
                    if(element.id === item.id && element.type === item.type) {
                        //found
                        index = mIndex;
                    }
                });

                /**
                 * Navigate into path arbitrary point
                 */
                const { path } = items;

                if(path.length - 1 < index && index < 0) {
                    throw new Error("The specified index does not exist in path");
                }

                items.path = path.slice(0, index + 1);

            } else if(navigationType === NAVIGATION_TYPE_DIRECTION_IN) {

                const { item } = action.payload;

                    if( ITEM_TYPE_STUDENT === item.type ) {
                        /**
                         * We navigate into student lets mark selectedStudent of our store to selected student id
                         * for next usage
                         */
                        items.selectedStudentId = item.id;
                    } else if(ITEM_TYPE_CLASS === item.type) {
                         /**
                         * We navigate into class lets mark selectedClass of our store to selected class id
                         * for next usage
                         */
                        items.selectedClassId = item.id;
                    }

                    /**
                     * we push everything into navigation stack except problem navigations
                     * for problem navigations we replace another problem with current one
                     */
                    if(items.path && items.path.length && items.path[items.path.length - 1].type === ITEM_TYPE_PROBLEM) {
                        items.path.pop();
                    }
                    items.path.push({
                        id: item.id,
                        name: item.name,
                        type: item.type,
                    });


            } else if(navigationType === NAVIGATION_TYPE_DIRECTION_OUT) {

                if(items.path.length <= 0) {
                    /**
                     * can not navigate out if there are no items in the path stack
                     */
                    throw new Error("can not navigate out if there are no items in the path stack");
                }
                const item = items.path.pop();
                // /**
                //  * In any navigation out we unmak selected problem, because problem select is done
                //  * at the last step, so if we stp back, there should not be any selected problem
                //  */
                // items.selectedProblemId = null;

                if(ITEM_TYPE_STUDENT === item.type) {
                    /**
                     * we pop out from selected student lets unmark in store
                     */
                    items.selectedStudentId = null;
                } else if(ITEM_TYPE_CLASS === item.type) {
                    /**
                     * we have move out from classlets nulify selected class id
                     */
                    items.selectedClassId = null;
                    items.selectedStudentId = null;
                }

            } 
        },
        itemsLoaded: (items, action) => {
            items.loading = false;
            items.list = action.payload.list;
        },
        loadedItemsTypeDetected:  (items, action) => {
            items.loadedItemsType = action.payload;
        },

        navigationRollback: (items, action) => {
            items.loading = false;
        },

        /**
         * 
         * update problem item submission data
         * 
         * @param {*} items 
         * @param {*} action 
         */
        problemSubmissiondataUpdated: (items, action) => {
            const selectedProblemId = getSelectedProblem(items).id;
            if(!items.list[selectedProblemId].submission) {
                //if first submission, create submission object
                items.list[selectedProblemId].submission = {};
            }
            for (const [key, value] of Object.entries(action.payload)) {
                /**
                 * update only submission fields that have been changed (came from backend)
                 */
                items.list[selectedProblemId].submission[key] = value;
            }
            
        },

        problemSeen: (items, action) => {
            const selectedProblem = getSelectedProblem(items);
            items.list[selectedProblem.id].submission.seen = true;
        }
    }
});


export default slice.reducer;
const { loadedItemsTypeDetected, containerSubmissionsCleaningFailed, containerSubmissionsCleaningStarted, containerSubmissionsCleaningFinished,
    navigationStarted, itemsLoaded, navigationRollback, itemStatusChangingFinished, 
    itemStatusChangingFailed, itemStatusChangingStarted, problemSeen } = slice.actions;

export const { navigationaPathUpdated, problemSubmissiondataUpdated, resetItems, toogleSelectedProblemSolutionVisibility, toogleSelectedProblemSubmissionLogVisibility} = slice.actions

export const changeProblemStatusSeen = (submissionId) => {
    return apiCallBegan({
        url: 'webapp/change-submission-seen',
        method: 'post',
        data: {submissionId},
        onStart: problemSeen.type,
    });
} 


export const cleanHomework = (containerId, studentId) => {
    return apiCallBegan({
        url: 'webapp/clean-homework',
        method: 'post',
        data: {containerId, studentId},
        onSuccess: [
            containerSubmissionsCleaningFinished.type,
            hideMessage.type
        ],
        onStart: [
            {
                type: containerSubmissionsCleaningStarted.type,
                payload: containerId
            },
            hideMessage.type
        ],
        onFail: containerSubmissionsCleaningFailed.type,
        onError: containerSubmissionsCleaningFailed.type
    });
}

export const reloadLastNavigation = (items) => {
    return navigate(getPathLastItem(items), NAVIGATION_TYPE_GOTO);
}

// Action creators
export const navigate = (item, navigationType = NAVIGATION_TYPE_DIRECTION_IN, otherPayloadData = {}) => (dispatch, getState) => {

    const navigationItemType = item.type;
    const { user, items, work } = getState();

    //if we go to a breadcrump item lets check which is its index
    if(NAVIGATION_TYPE_GOTO === navigationType) {

        otherPayloadData.item = item;
    }

    //disable management VIEWS
    dispatch(addNewUserToClassViewHidden());
    dispatch(addEditItemViewHidden());


    if( ITEM_TYPE_ROOT === navigationItemType ) {
        return _loadClasses(dispatch, navigationType, otherPayloadData);
    } else if( ITEM_TYPE_PROBLEM === navigationItemType) {
        const hidrateWork =  !getWorkAnswer(work, item) || !isProblemSeen(item); //hidrate the work if it is emty (not hidrated yet) or if there are changes
        return _loadProblem(dispatch, item, navigationType, hidrateWork);
    } else if(ITEM_TYPE_STUDENT === navigationItemType) {
        return _loadItems(dispatch, user, items, item, navigationType, otherPayloadData)
    } else if(ITEM_TYPE_CLASS === navigationItemType) {
        if(isTeacherUser(user)) {

            //We load students only in operational view, otherwise just load class contents
            if(isOperationalView(user)) {
                /**
                 * FOR TEACHER we load students of class first, then containers
                 */
                return _loadStudents(dispatch, item, navigationType, otherPayloadData);
            }
        } else {
            return _loadItems(dispatch, user, items, item, navigationType, otherPayloadData)
        }
        /**
         * otherwise we load class containers
         */
        return _loadItems(dispatch, user, items, item, navigationType, otherPayloadData);
    } else if(ITEM_TYPE_CONTAINER) {
        return _loadItems(dispatch, user, items, item, navigationType, otherPayloadData);
    }
    throw new Error("Invalid items type");
}


export const enableDisableItem = ({url, data}, status) => (dispatch, getState) => {
    return dispatch(apiCallBegan({
        url,
        method: 'post',
        data: {...data, status},
        onStart: {
            type: itemStatusChangingStarted.type,
            payload: data.containerId
        },
        onError: itemStatusChangingFailed.type,
        onFail: itemStatusChangingFailed.type,
        onSuccess: itemStatusChangingFinished.type,
    }));
}

/****************************************************
 *                                                  *
 *         Navigation action private loaders        *
 *                                                  *
 ****************************************************/

const _loadProblem = (dispatch, item, navigationType, hidrateWork) => {
    /**
     * for problem select (load) action we do no api calls, all data
     * exist in our store, we just initiate navigated reducer in order problem to be marked as select
     */
    dispatch(navigationaPathUpdated({
        type: navigationType, //this is always direction in, can not be out
        item
    }));

    /**
     *
     * if problem has submitted answer in we should hidrate the work (select the answer in work)
     * but we hidrate only once so if work is already hidrated we do not touch it
     *  
     **/
    if(isSubmissionAnswered(item) && hidrateWork) {
        dispatch(problemAnswerChanged({
            id: item.id, 
            value: item.submission.answer
        }));
    }

}

const _loadStudents = (dispatch, item, navigationDirection, otherPayloaddata = {}) => {
    return dispatch(getLoadStudentsAction(item, navigationDirection, otherPayloaddata));
}

export const getLoadStudentsAction = (item, navigationDirection, otherData) => {
    const apiAction = `/webapp/get-class-students/${item.id}`;
    return _navigationLoaderAction(apiAction, {}, navigationDirection, item, otherData);
}

export const getLoadClassesAction = () => {
    const apiAction = '/webapp/get-classes';
    const data = {};
    return _navigationLoaderAction(apiAction, data, NAVIGATION_TYPE_GOTO, ROOT_PATH, {});
}

export const getLoadContainersAction = (parentItem) => {
    const apiAction = '/webapp/get-items';

    const data = {};
    if(parentItem.type === ITEM_TYPE_CLASS) {
        data.classId = parentItem.id;
    } else {
        data.parentContainerId = parentItem.id;
    }

    return _navigationLoaderAction(apiAction, data, NAVIGATION_TYPE_GOTO, parentItem, {});
}



/**
 * We do not know what type of items are there, containers or problems, thats why we call theem items
 */
const _loadItems = (dispatch, user, items, item, navigationDirection, otherPayloaddata = {}) => {
    const apiAction = '/webapp/get-items';
    const data = {};
    if(item.type === ITEM_TYPE_CLASS) {
        data.classId = item.id;
    } else if(item.type === ITEM_TYPE_STUDENT) {
        data.classId = items.selectedClassId;
    } else {
        data.parentContainerId = item.id;
    }

    if(isTeacherUser(user)) {

        /**
         * for manage contents viewwe do not pass students data, as we need only class contents
         */
        if(isOperationalView(user)) {
            /**
             * for teacher we load items for already selected student
             */
            if(items.selectedStudentId) {
                data.studentId = items.selectedStudentId;
            } else {
                data.studentId = item.id;
            }
        }
    }

    return dispatch(_navigationLoaderAction(apiAction, data, navigationDirection, item, otherPayloaddata));
}


const _loadClasses = (dispatch, navigationDirection, otherPayloaddata = {}) => {
    const apiAction = '/webapp/get-classes';
    const data = {};
    return dispatch(_navigationLoaderAction(apiAction, data, navigationDirection, ROOT_PATH, otherPayloaddata));
}



const _navigationLoaderAction = (apiAction, data, navigationDirection, item, otherPayloadData = {}) => {
    
    let toBeLoadItemsType = null;

    /**
     * @TODO Use strategy pattern to decide what to laod
     */
    switch(item.type) {
        case ITEM_TYPE_ROOT:
            toBeLoadItemsType = ITEM_TYPE_CLASS;
            break;

        case ITEM_TYPE_CLASS:
            toBeLoadItemsType = ITEM_TYPE_STUDENT;
            break;


        case ITEM_TYPE_STUDENT:
            toBeLoadItemsType = ITEM_TYPE_CONTAINER;
            break;
    

        case ITEM_TYPE_CONTAINER:
            toBeLoadItemsType = ITEM_TYPE_CONTAINER_OR_PROBLEM;
            break;

        default:
            throw Error("Invalid item to load in");
    }

    return apiCallBegan({
        url: apiAction,
        method: 'post',
        data,
        onError: navigationRollback.type,
        onFail: navigationRollback.type,
        onStart: 
            [{
                type: navigationStarted.type,
                payload:item
            }],
        onSuccess: [
            {
                type: navigationaPathUpdated.type,
                payload: {
                    ...otherPayloadData,
                    type: navigationDirection,
                    item,
                }
            },

            itemsLoaded.type,
            {
                type: loadedItemsTypeDetected.type,
                payload: toBeLoadItemsType
            }
        ],
    });
}