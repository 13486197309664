import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { request } from "../lib/request";
import { getPathLastContainerItem, getPathLastItem } from "../lib/store/getters";
import { getLoadContainersAction, ITEM_TYPE_CLASS, ITEM_TYPE_CONTAINER } from "../lib/store/items";
import { addEditItemViewHidden } from "../lib/store/manage";

var md5 = require('md5');

const ClassBulkUploadContainer = styled.div`
    margin-top:20px;
    
    .uploading {
        width: 99%;
        text-align: center;
    }

    .ellipses {
        color: green;
        white-space: nowrap;                   
        overflow: hidden;
        text-overflow: ellipsis;  
        width: 99%;
        max-width:600px;
        
        /* Beginning of string */
        direction: rtl;
        text-align: center;
    }
`;


const uploadProblemsWithSolutions = async (folderOrClassContainer, problems, solutions, setProblemSubmitting, setUploadingProblemsNumber) => {

    let mainUrl = null;
    if(folderOrClassContainer.type === ITEM_TYPE_CONTAINER) {
        mainUrl = `webapp/manage/container-problems-and-solutions-bulk-upload/null/${folderOrClassContainer.id}`;
    } else {
        mainUrl = `webapp/manage/container-problems-and-solutions-bulk-upload/${folderOrClassContainer.id}`;
    }
    const createdContainers = {};

    let uploadingProblemNumber = 1;
    
    for (const [hash, problem] of Object.entries(problems)) {
        setUploadingProblemsNumber(uploadingProblemNumber++);
        let finalUrl = mainUrl;
        const formData = new FormData();
        setProblemSubmitting(`${problem[0]}/${problem[1].name}`)
        formData.append('problem', problem[1]);
        formData.append('path', problem[0]);
        if(createdContainers[problem[0]]) {
            finalUrl = `${mainUrl}/${createdContainers[problem[0]]}`
        } 

        if(solutions[hash]) {
            formData.append('solution', solutions[hash]);
        }

        try {
            const response = await request(finalUrl, 'POST', formData);
            if(response.container) {
                createdContainers[response.path] = response.container;
            }
        } catch (ex) {
            console.log("Invalid response")
        }
    }

}

export const ClassContainerBulkUploadComponent = ({items, dispatch}) => {

    const [isLoading, setIsLoading] = useState(false)
    const [problemSubmitting, setProblemSubmitting] = useState()
    const [uploadingProblemsNumber, setUploadingProblemsNumber] = useState(0)
    const [problemsCount, setProblemsCount] = useState(0)

    // const [containerIdsCreated]

    const container = getPathLastContainerItem(items);
    
    if(![ITEM_TYPE_CLASS, ITEM_TYPE_CONTAINER].includes(container.type)) {
        throw new Error("Container should be a class");
    }
    const dragTextDropping = 'Drop problems and solutions base folder with images here';
    const dragTextWaiting = "Drag 'n' drop sproblems and solutions base folder with images";
    


    const onDrop = useCallback(acceptedFiles => {
        
        setIsLoading(true)

        // console.log(acceptedFiles);
        const problems = {};
        const solutions = {};


        //sort problems with their solutions by folders
        acceptedFiles.forEach(file => {

            if(['image/jpeg', 'image/png'].includes(file.type)) {

                const path = file.path.split('/');
                path.shift();
                if(path.length) {
                    let problemName = path.pop(); //remove filename
                    if(path[path.length-1].toLowerCase() === 'solutions') { //add to solutions
                        path.pop(); //remove "solution" name from path list
                        const hash = path.join('/') + '/' + problemName;
                        const pathMd5 = md5(hash);
                        solutions[pathMd5] = file;
                    } else {
                        problemName = problemName.split('*');
                        problemName = problemName.pop();
                        problemName = problemName.replace(/([0-9]*){1}([-\d\w])*(\.png|jpeg|jpg)+/i, '$1$3');
                        const hash = path.join('/') + '/' + problemName;
                        const pathMd5 = md5(hash);
                        problems[pathMd5] = [path.join('/'), file];
                    }
                }
            }
        });

        setProblemsCount(Object.keys(problems).length)

        uploadProblemsWithSolutions(container, problems, solutions, setProblemSubmitting, setUploadingProblemsNumber).then(() => {
            setIsLoading(false)
            dispatch(getLoadContainersAction(getPathLastItem(items)))
            dispatch(addEditItemViewHidden())
        });



    }, [items, container, dispatch])

    const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})


    if(isLoading) {
        return (
            <ClassBulkUploadContainer>
                <div className="uploading">
                    <div>Uploading {uploadingProblemsNumber} of {problemsCount}</div>
                    <div className="ellipses">
                        {problemSubmitting}
                    </div>
                </div>
            </ClassBulkUploadContainer>
        )
    }

    return (
        <ClassBulkUploadContainer>
            <div key="problems">
                <div key="title">Uplaod folder with problems and solutions here</div>
                <div className="drop-zone" {...getRootProps()}>
                    <input {...getInputProps()} />
                    {
                    isDragActive ?
                        <p className="dropping">{dragTextDropping}</p> :
                        <p className="waiting">{dragTextWaiting}</p>
                    }
                </div>
            </div>
        </ClassBulkUploadContainer>
    )
}
