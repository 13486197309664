import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "./api";
import { navigate, NAVIGATION_TYPE_GOTO, resetItems, ROOT_PATH } from "./items";
import { resetWork } from "./work";
import { showModal } from "./message";
import { VIEW_MANAGE_CONTENT, VIEW_OPERATIONAL } from "../constants";
import { isTeacherUser } from "./getters";

const slice = createSlice({
    name: 'user',
    initialState: { 
        data: null,
        view: VIEW_OPERATIONAL
    },
    reducers: {
        userViewChanged: (user, action) => {
            switch(action.payload) {
                case VIEW_OPERATIONAL:
                    user.view = VIEW_OPERATIONAL;
                    break;
                case VIEW_MANAGE_CONTENT: 
                    if(!isTeacherUser(user)) {
                        throw Error("Student can not switch to manage content view")
                    }
                    user.view = VIEW_MANAGE_CONTENT;
                    break;
                default:
                    throw Error("Invalid, unsuported view")
            }
        },
        userLoggedIn: (user, action) => {
            user.data = action.payload;
        },
        userLogedOut: (user, action) => {
            user.data = false;
            user.view = VIEW_OPERATIONAL;
        },
        userLogInStart: (user) => {
            user.data = null;
        },
        userLogInFailed: (user) => {
            user.data = false
        },
        userLoaded: (user, action) => {
            user.data = action.payload;
        },
    }
});

export default slice.reducer;
const { userLoggedIn, userLogInFailed, userLogInStart, userLoaded } = slice.actions;

export const {userLogedOut, userViewChanged} = slice.actions;

// Action creators

export const swicthToManageView = (dispatch, items) => {
    dispatch(userViewChanged(VIEW_MANAGE_CONTENT));
    // if(items.loadedItemsType === ITEM_TYPE_STUDENT) {
        dispatch(navigate(ROOT_PATH, NAVIGATION_TYPE_GOTO))
    // }
}

export const logInUser = (username, password) => apiCallBegan({
    url: '/action/login',
    method: 'post',
    data: { username, password },
    onStart: userLogInStart.type,
    onSuccess: userLoggedIn.type,
    onError: userLogInFailed.type,
    onFail: [
        userLogedOut.type, 
        {
            type: showModal.type, payload: "Invalid cridentials"
        }
    ],
});


export const logOutUser = () => apiCallBegan({
    url: '/action/logout',
    method: 'post',
    data: {},
    onSuccess: [userLogedOut.type, resetItems.type, resetWork.type],
});

export const loadUser = () => {
    return apiCallBegan({
        url: 'action/get-user',
        method: 'get',
        data: {},
        onSuccess: userLoaded.type,
        onFail: [userLogedOut.type, resetItems.type],
        onError: [userLogedOut.type, resetItems.type],
    });
}