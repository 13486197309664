// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
// import { useState } from 'react';

const ItemsSortingWrapper = ({id, children}) => {


    return (
        <>
            {children}
        </>
    );

    // const [characters, updateCharacters] = useState(children);

    // function handleOnDragEnd(result) {
    //     if (!result.destination) return;
    
    //     const items = Array.from(children);
    //     const [reorderedItem] = items.splice(result.source.index, 1);
    //     items.splice(result.destination.index, 0, reorderedItem);
    
    //     updateCharacters(items);
    // }

    // return (
    //     <DragDropContext onDragEnd={handleOnDragEnd}>
    //         <Droppable droppableId="l_items">
    //             {(provided) => (
    //                 <div className="l_items" {...provided.droppableProps} ref={provided.innerRef}>
    //                     {children.map((element, index) => {
    //                         return (
    //                             <Draggable draggableId={`item_${element.key}`} key={`item_${element.key}`} index={index}>
    //                                 {(provided) => (
    //                                     <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
    //                                         {element}
    //                                     </div>
    //                                 )}
    //                             </Draggable>
    //                         )
    //                     })}
    //                     {provided.placeholder}
    //                 </div>
    //             )}
    //         </Droppable>
    //     </DragDropContext>
    // );
    
};

export default ItemsSortingWrapper;