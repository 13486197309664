import { useSelector } from "react-redux";
import styled from "styled-components";
import { backendHost, MANAGE_ACTION_TAB_CLASS_ADD, MANAGE_ACTION_TAB_CLASS_EDIT, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT } from "../lib/constants";
import image from '../res/images/icon_logo.png'
import MainActionsBlock from '../components/mainActionsBlock'
import { useRef } from "react";
import { getSelectedClassId, getSelectedClassName, getSelectedProblem, getManagementVisibleTab, isAddNewUserToClassViewVisible, getManagementOtherData } from "../lib/store/getters";

import ManageStudents from "./manageStudents";
import ManageClasses from "./manageClasses";
import ManageContainers from "./manageContainers";



const ProblemImageBlock = styled.div`
    flex-grow: 1;
    padding: 0;
    overflow: scroll;
    margin: 20px 20px 20px 40px;

    h2 {
        margin-top:70px;
    }

    .solution {
        margin-bottom: 50px;
    }
`;

const ContentMainBlock = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    min-height:100%;
    margin: 0;
    padding: 0;
    background-color: white;
`;



const ContentMain = () => {
    const { items, manage } = useSelector(state => state)
    const _selectedProblem = getSelectedProblem(items);
    const solutionImageBlockRef = useRef(null);

    const currentClassName = getSelectedClassName(items)

    let defaultTab = null;

    if((defaultTab = getManagementVisibleTab(manage)) !== null) {
    
        if([MANAGE_ACTION_TAB_CLASS_EDIT, MANAGE_ACTION_TAB_CLASS_ADD].includes(defaultTab)) {
            return (
                <ManageClasses
                    items={items} defaultTab={defaultTab} otherData={getManagementOtherData(manage)}
                />
            )
        } else if([MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT].includes(defaultTab)) {
            return (
                <ManageContainers
                    items={items} defaultTab={defaultTab} otherData={getManagementOtherData(manage)}
                />
            )
        } else {
            return (
                <>Barev dzez {defaultTab}hajox</>
            )
        }
        
    } else if(_selectedProblem) {
        return (
            <ContentMainBlock>
                <ProblemImageBlock>
                    <img src={`${backendHost}/${_selectedProblem.problemImage}`} 
                        alt="Problem here" 
                        style={{ width: "100%" }}/>
                    {_selectedProblem.solutionVisible && _selectedProblem.solutions.length ? (
                        <>
                        <h2 ref={solutionImageBlockRef} style={{textAlign: "center"}}>Solution</h2>
                        <img className="solution" alt="solution" onLoad={() => solutionImageBlockRef.current.scrollIntoView()} style={{ width: "100%" }} src={_selectedProblem.solutions[0]}/></>
                    ) : (<></>)}
                </ProblemImageBlock>
                <MainActionsBlock/>
            </ContentMainBlock>
        );
    } else if(isAddNewUserToClassViewVisible(manage)) {
        return (
            <ManageStudents
                currentClassName={currentClassName}
                currentClassId={getSelectedClassId(items)} 
                items={items}/>
        )
    } else {
        return (
            <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                <img src={image} alt="Hey," style={{width: "100px"}}/>
            </div>
        );
    }
}

export default ContentMain;