import React, { useEffect } from 'react';
import LoginComponnet from '../components/loginComponent';
import LoggedInComponent from '../components/loggedInComponent';
import { HashRouter, Route, Switch, } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { loadUser } from '../lib/store/user';
import { hideMessage, MESSAGE_TYPE_MODAL_ALERT, MESSAGE_TYPE_MODAL_CONFIRMATION, MESSAGE_TYPE_TOAST } from '../lib/store/message';
import Modal from 'react-bootstrap/Modal';
import Toast from 'react-bootstrap/Toast'
import Button from 'react-bootstrap/Button'
import styled from 'styled-components';

const ToastContainer = styled.div`
    position:absolute;
    bottom: 80px;
    right: 180px;
`;

const MessageBox = () => {
  const dispatch = useDispatch();
  const message = useSelector((state) => state.message)

  if(message.type === MESSAGE_TYPE_MODAL_ALERT) {
    return (
      <>
        <Modal show={true} onHide={() => dispatch(hideMessage({}))}>
          <Modal.Header closeButton>
            <Modal.Title>Error</Modal.Title>
          </Modal.Header>
          <Modal.Body>{message.body}</Modal.Body>
        </Modal>
      </>
    );
  } else if(message.type === MESSAGE_TYPE_TOAST) {
    return (
      <ToastContainer>
        <Toast animation={true} className="bg-dark" onClose={() => dispatch(hideMessage({}))} show={true} delay={3000} autohide>
          <Toast.Body className="text-white">{message.body}</Toast.Body>
        </Toast>
      </ToastContainer>
    )
  } else if(message.type === MESSAGE_TYPE_MODAL_CONFIRMATION) {
    return (
      <Modal show={true} onHide={() => dispatch(hideMessage({}))}>
          <Modal.Header closeButton>
              <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>{message.body}</Modal.Body>
          <Modal.Footer>
              <Button variant="secondary" onClick={() => dispatch(hideMessage({}))}>
                  No
              </Button>
              <Button variant="primary" onClick={() => {dispatch(message.data.onConfirmationAction)} }>
                  Yes
              </Button>
          </Modal.Footer>
      </Modal>
    )
  }

  return (<></>);
}


function Router() {

  const user = useSelector(state => state.user)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user.data === null) {
    return (
      <div>
        Loading...
      </div>
    );
  } else if (user.data === false) {
    return (
      <>
        <LoginComponnet />
        <MessageBox />
      </>
    );
  } else {
    return (
      <>
        <HashRouter>
          <Switch>
            <Route path="/:path?" component={LoggedInComponent} />
          </Switch>
        </HashRouter>
        <MessageBox />
      </>
    );
  };
}

export default Router;