/**
 * helper pure functions
 */

import moment from "moment";
import { CORRECTION_STATUS_CORRECT, PROBLEM_TYPE_TEST, USER_ROLE_TEACHER, USER_ROLE_STUDENT, VIEW_MANAGE_CONTENT, VIEW_OPERATIONAL } from "../constants";
import { ITEM_TYPE_CLASS, ITEM_TYPE_CONTAINER, ITEM_TYPE_PROBLEM, ITEM_TYPE_ROOT, ROOT_PATH } from "./items";

export const getWorkOrSubmissionCorrectionStatus = (work, problem) => work.correctionStatuses[problem.id] ? work.correctionStatuses[problem.id] : problem.submission && problem.submission.correctionStatus ? problem.submission.correctionStatus : null
 
export const getWorkAnswer = (work, problem) => work.answers[problem.id];

export const getWorkOrSubmissionUserAnswer = (work, problem) => {
    const workSelectedAnswer = getWorkAnswer(work, problem);
    if(workSelectedAnswer !== null && workSelectedAnswer !== undefined) {
        return workSelectedAnswer;
    } else if(problem.submission) {
        return problem.submission.answer;
    }
    return null;
}


export const isSubmissionAnswered = (problem) => problem.submission && problem.submission.answer !== null && problem.submission.answer !== '';

export const getSolutionUrl = (problem) =>  problem.solutions && problem.solutions.length && problem.solutions[0];

export const isSubmissionRightAnswered = (problem) => {return problem.submission && problem.submission.correctionStatus === CORRECTION_STATUS_CORRECT};

export const canViewSolution = (problem) => problem.problemType === PROBLEM_TYPE_TEST && (problem.canViewSolution || (problem.submission && problem.submission.canViewSolution))

export const isProblemActable = (problem) => problem && problem.problemType === PROBLEM_TYPE_TEST 

export const getSelectedProblem = (items) => items && items.path.length && items.path[items.path.length - 1].type === ITEM_TYPE_PROBLEM && items.list[items.path[items.path.length - 1].id];

export const getSelectedClassName = items => items && items.path.length && items.path.reduce((accumulator, currentValue) => currentValue.type === ITEM_TYPE_CLASS ? accumulator + currentValue.name : '', '');

export const getSelectedClassId = items => items && items.selectedClassId;

export const getPathLastItem = items => items && items.path && items.path.length && items.path[items.path.length - 1];

export const isAddNewUserToClassViewVisible = manage => manage.addNewUserToClassViewVisible;

export const getManagementVisibleTab = manage => manage.managemenetVisibleTab;

export const getManagementOtherData = manage => manage.managementOtherData;

export const isTeacherUser = ({data}) => USER_ROLE_TEACHER === data.user_role;
  
export const isStudentUser = ({data}) => USER_ROLE_STUDENT === data.user_role;   

export const isManageContentView = ({view}) => VIEW_MANAGE_CONTENT === view;

export const isOperationalView = ({view}) => VIEW_OPERATIONAL === view;

export const getProblemChoicAnswersMap = (problem) => JSON.parse(problem.answersMap);

export const canSubmitProblem  = (user, problem) => !isTeacherUser(user) && !isSubmissionRightAnswered(problem);

export const canProblemAnswerChanged = (user, problem) => !isSubmissionRightAnswered(problem) && !isTeacherUser(user);

export const getProblemSolvingSpentTime = (work, problemId) => {
    if(work.currentProblem && work.currentProblemWorkingStartTime) { //if current problem and current problem working start time exist (they should)
        if(problemId === work.currentProblem) {
            return moment().unix() - work.currentProblemWorkingStartTime;
        } else if(work.timeSpent && work.timeSpent[work.currentProblem]) {
            return work.timeSpent[work.currentProblem];
        }
    }

    return 0;
}

export const containerHasSubmissions = (containerItem) => containerItem.submissionsCount > 0;

export const canEraseContainerSubmissions = (user, items, container) => isTeacherUser(user) && items.selectedStudentId && containerHasSubmissions(container);

export const isErasingContainerSubmissions = (items, containerId) => items.cleaningContainerSubmissionsId === containerId;

export const isProblemSeen = (problem) => problem.submission ? !!problem.submission.seen :  true;

export const getCurrentNavigationContainerType = (items) => {
    const pathLastItemType = getPathLastItem(items).type;

    if(pathLastItemType === ITEM_TYPE_PROBLEM) {
        //ignore selected problem
        return ITEM_TYPE_CONTAINER;
    }

    return pathLastItemType;
}

/**
 * 
 * We assume that items list should be or containers or problems, we do not accept mixed types in container
 * 
 * @param {*} items
 *  
 */
export const getcurrentContainerItemsType = (items) => {
    const values = Object.values(items.list);
    return values.length ? values[0].type : null;
}

export const getlistItemById = (items, id) => items.list[id] ?? null;

export const getPathLastContainerItem = (items) => {
    if(items && items.path && items.path.length) {
        const pathCopy = [...items.path];
        while(pathCopy.length) {
            const item = pathCopy.pop();
            if([ITEM_TYPE_CLASS, ITEM_TYPE_CONTAINER, ITEM_TYPE_ROOT].includes(item.type)) {
                return item;
            }
        }

        return null;
        
    }
}

export const STRATEGY_UCCP = 'uccp';
export const STRATEGY_CUCP = 'cucp';

export const changeViewStrategy = strategy => {
    if(strategy === STRATEGY_UCCP) {
        ROOT_PATH['name'] = 'users'
    } else if(strategy === STRATEGY_CUCP) {
        ROOT_PATH['name'] = 'classes'
    }
} 