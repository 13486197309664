import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { UnselectableCss } from '../helper/mainStyledBlocks';
import { navigate, NAVIGATION_TYPE_GOTO, ROOT_PATH } from '../lib/store/items';
import { logOutUser, swicthToManageView, userViewChanged } from '../lib/store/user';
import { getSelectedProblem, isOperationalView, isTeacherUser } from '../lib/store/getters';
import { Breadcrumb } from 'react-bootstrap';
import { VIEW_OPERATIONAL } from "../lib/constants";
import { addEditItemViewHidden } from "../lib/store/manage";



const BreadcrumbContainer = styled.div`
    display: flex;
    flex-direction:row;
    color: white;
    margin-left: 10px;
`;

const HeaderBlockContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 50px;
    min-height: 50px;
    font-size: 0.92em;
    font-weight: 600;
    background-color: #7278ef;
`;

const BreadcrumbItemInner = styled.div`
    text-overflow: ellipsis;
    max-width: 200px;
    overflow: hidden;
    font-size: 0.85rem;
    white-space: nowrap;
`;

const Dropdown = styled.div`
    ${UnselectableCss}
`;


const ProfileDropdown = ({user, items}) => {
    const dispatch = useDispatch();

    let changeViewToggle = (<></>);

    if(isTeacherUser(user)) {
        if(isOperationalView(user)) {
            changeViewToggle = (
                <button className="dropdown-item" onClick={() => swicthToManageView(dispatch, items)}>Switch to Manage content</button>
            )
        } else {
            changeViewToggle = (
                <button className="dropdown-item" onClick={() => 
                    {
                        dispatch(addEditItemViewHidden()) 
                        dispatch(userViewChanged(VIEW_OPERATIONAL)) 
                        dispatch(navigate(ROOT_PATH, NAVIGATION_TYPE_GOTO))
                    }
                }>Switch to Operational</button>
            )
        }
    }
    
    let reportAnIssue = (<></>);
    const selectedProblem = getSelectedProblem(items);
    if(selectedProblem) {
        reportAnIssue = (
            <>
                <div className="dropdown-divider"></div>
                <a className="dropdown-item" href="#6">Report an issue with {selectedProblem.id}</a>
            </>
        );
    }

    return (
        <div className="row c-header">
            <div className="col-6 ch-right mr-auto d-flex">
                
            </div>
            <div className="col-6 ch-left ml-auto d-flex">
                <Dropdown className="dropdown">
                    <button className="btn btn-custom dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        {user.data?.user_fname} {user.data?.user_lname}
                    </button>
                    <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a className="dropdown-item" href="#5">My Account</a>
                        <p className="big-divider">&nbsp;</p>
                        <a className="dropdown-item" href="#4">Tell a friend</a>
                        <div className="dropdown-divider"></div>
                        <a className="dropdown-item" href="#3">Contact us</a>
                        {reportAnIssue}
                        <p className="big-divider">&nbsp;</p>
                        <a className="dropdown-item" href="#2">About K12</a>
                        <div className="dropdown-divider"></div>
                        <button className="dropdown-item" onClick={() => dispatch(logOutUser())}>Sign out</button>
                        {changeViewToggle}
                    </div>
                </Dropdown>
            </div>
        </div>
    );
}

const Breadcrump = ({items}) => {
    const dispatch = useDispatch();

    const breadcrumbActions = [];

    items.path.forEach((item, index, array) => {
        const lastItem = index === array.length - 1;
        breadcrumbActions.push(
            (
                <Breadcrumb.Item 
                    active = {lastItem ? 'active' : ''} 
                    key={index}
                    href="#"
                    onClick={lastItem ? () => false : () => dispatch(navigate(item, NAVIGATION_TYPE_GOTO))}
                >
                    <BreadcrumbItemInner title={item.name}>{item.name}</BreadcrumbItemInner></Breadcrumb.Item>
            )
        )
    });
    
    return (
        <BreadcrumbContainer>
            {/* {showPrev ? (
                <ArrowRight key={91} onClick = {() => dispatch(navigateOut)}>
                    {!items.loading ? (<FontAwesomeIcon icon={faArrowLeft} />):(<></>)}
                </ArrowRight>
            ) : (<ArrowRight key={94} />)} */}
            <Breadcrumb key={"breadcrumb"}>
                {breadcrumbActions}
            </Breadcrumb>
        </BreadcrumbContainer>
    )
}


const HeaderBlock = (props) => {
    const items = useSelector((state) => state.items);
    const user = useSelector(state => state.user);

    return (
        <HeaderBlockContainer key={44} className="cn-title">
            <Breadcrump items={items} />
            <ProfileDropdown key={99} items = {items} user={user}/>
        </HeaderBlockContainer>
    );
}

export default HeaderBlock;