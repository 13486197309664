import { Alert, Button, Form, Tab, Tabs } from "react-bootstrap";
import { useCallback, useEffect, useReducer, useRef } from "react";
import styled from "styled-components";
import { request } from "../lib/request";
import Select from 'react-select'
import ClassStudents from "./classStudents";
import { applyMultiOnClassStudents } from "../helper/helpers";
import { useDispatch } from "react-redux";
import { addEditClass } from "../lib/store/manage";
import { MANAGE_ACTION_TAB_CLASS_ADD, MANAGE_ACTION_TAB_CLASS_EDIT } from "../lib/constants";

const ManageClassesContainer = styled.div`
    margin: 30px auto;
    width: 90%;
    padding: 0px 80px;


    .tab-content {
        overflow: scroll;
        height: 95%;
    }

    .multi-wrapper .header {
        position: absolute;
        top: 45px;
    }

    .non-selected-wrapper, .selected-wrapper {
        margin-top:40px;
        
    }
    
    .multi-wrapper {
        position: relative;
    }

    .multi-wrapper .non-selected-wrapper, .multi-wrapper .selected-wrapper {
        height: 300px !important;
    }

    .btn-container {
        text-align: center;
        margin-top: 20px;
    }

    .create-new-class {
        margin-top: 20px;
    }
`;





const getClassOptionsFromReduxStoreItemsList = (list) => {
    const options = [];
    for (const [key, value] of Object.entries(list)) {
        options.push({label: value.name, value: key});
    }


    return options;
}



/**
 * CLASS ADD EDIT DATA REDUCER PART
 */

const DATA_DISPATCHER_ACTION_RESET = 'reset';
const DATA_DISPATCHER_ACTION_LOAD_CLASS = 'load-class';
const DATA_DISPATCHER_ACTION_CLASS_LOADED = 'lass-loaded';
const DATA_DISPATCHER_ACTION_CLASS_LOAD_FAILED = 'lass-load-failed';
const DATA_DISPATCHER_ACTION_CLASS_NAME_CHANGED = 'class-name-editied';
const DATA_DISPATCHER_ACTION_CLASS_BACKUP_TECAHER_CHANGED = 'class-bt-editied';
const DATA_DISPATCHER_ACTION_CLASS_GRADE_CHANGED = 'class-gd-editied';
const DATA_DISPATCHER_ACTION_CLASS_AUTOMATED_ANSWER_FLAG_CHANGED = 'class-aaf-editied';
const DATA_DISPATCHER_ACTION_START_LOADING_AVAILABLE_BACKUP_TEACHERS = 'tchs-loading';
const DATA_DISPATCHER_ACTION_AVAILABLE_BACKUP_TEACHERS_LOADED = 'tchs-loaded';
const DATA_DISPATCHER_ACTION_AVAILABLE_BACKUP_TEACHERS_LOADED_FAILED = 'tchs-loaded-failed';
const DATA_DISPATCHER_ACTION_AVAILABLE_STUDENTS_LOAD_FAILED = 'stnds-loaded-failed';
const DATA_DISPATCHER_ACTION_START_LOADING_AVAILABLE_STUDENTS = 'stnds-start-loadeing';
const DATA_DISPATCHER_ACTION_AVAILABLE_STUDENTS_LOADED = 'stnds-loaded';

const addEditFormDataReducerInitialState = {
    type: MANAGE_ACTION_TAB_CLASS_ADD,
    selectedClassId: false, //initial value is false, when starting to load the value is changing to null, after finishinf to load the state either is changed to false again (if error happened) or the value of selected class id
    availableBackupTeachers: [],
    availableStudents: [],
    classStudents: [],
    className: '',
    classBackupTeacher: null,
    classGrade: 1,
    classAutomaticAnswerFlag: false
}

const addEditFormDataReducer = (state, action) => {
    switch(action.type) {
        case DATA_DISPATCHER_ACTION_START_LOADING_AVAILABLE_BACKUP_TEACHERS: 
            return {
                ...state,
                availableBackupTeachers: null
            }
        case DATA_DISPATCHER_ACTION_START_LOADING_AVAILABLE_STUDENTS: 
            return {
                ...state,
                availableStudents: null,
                classStudents: []
            }
        case DATA_DISPATCHER_ACTION_AVAILABLE_STUDENTS_LOADED: 
            return {
                ...state,
                availableStudents: action.payload,
                classStudents: []
            }
        case DATA_DISPATCHER_ACTION_AVAILABLE_STUDENTS_LOAD_FAILED: 
            return {
                ...state,
                availableStudents: [],
                classStudents: []
            }
        case DATA_DISPATCHER_ACTION_AVAILABLE_BACKUP_TEACHERS_LOADED: 
            return {
                ...state,
                availableBackupTeachers: action.payload
            }
        case DATA_DISPATCHER_ACTION_AVAILABLE_BACKUP_TEACHERS_LOADED_FAILED: 
            return {
                ...state,
                availableBackupTeachers: []
            }
        case DATA_DISPATCHER_ACTION_RESET: 
            return {
                ...addEditFormDataReducerInitialState,
                availableBackupTeachers: state.availableBackupTeachers //keep availableBackupTeachers value, we load it once, per component mount
            };
        case DATA_DISPATCHER_ACTION_LOAD_CLASS: 
            return {
                ...state,
                type: MANAGE_ACTION_TAB_CLASS_EDIT,
                selectedClassId: null
            }
        case DATA_DISPATCHER_ACTION_CLASS_LOADED:
            return {
                ...state,
                type: MANAGE_ACTION_TAB_CLASS_EDIT,
                selectedClassId: action.payload.data.selectedClassId,
                className: action.payload.data.className,
                classBackupTeacher: action.payload.data.classBackupTeacher,
                classGrade: action.payload.data.classGrade,
                classAutomaticAnswerFlag: action.payload.data.classAutomaticAnswerFlag,
                classStudents: action.payload.data.classStudents
                //finalize
            }
        case DATA_DISPATCHER_ACTION_CLASS_LOAD_FAILED: 
            return {
                ...state,
                type: MANAGE_ACTION_TAB_CLASS_EDIT,
                selectedClassId: false
            }
        
        //edit actions
        case DATA_DISPATCHER_ACTION_CLASS_NAME_CHANGED: 
            return {
                ...state,
                className: action.payload
            }
        case DATA_DISPATCHER_ACTION_CLASS_AUTOMATED_ANSWER_FLAG_CHANGED: 
            return {
                ...state,
                classAutomaticAnswerFlag: action.payload
            }
        case DATA_DISPATCHER_ACTION_CLASS_BACKUP_TECAHER_CHANGED: 
            return {
                ...state,
                classBackupTeacher: action.payload
            }
        case DATA_DISPATCHER_ACTION_CLASS_GRADE_CHANGED: 
            return {
                ...state,
                classGrade: action.payload
            }
    
        default:
            throw Error("Invalid reducer action");
    }
}

/**
 * END CLASS ADD EDIT DATA REDUCER PART
 */


const drawForm = (classStudentsComponentRef, data, dataChangeDispatcher, submitHandler) => {

    const errorMessage = data.errorMessage;


    return (
        <div key={"form"} className="create-new-class">
            {errorMessage ? 
                (<Alert key={"error"} variant={"danger"}>
                    {errorMessage}
                </Alert>) : (<></>)}
            <Form key="Form" noValidate validated={data.validated} onSubmit={submitHandler}>
                <Form.Group key={`${data.type}_name`} controlId={`${data.type}_name`}>
                    <Form.Label>Name</Form.Label>
                    <Form.Control type="text" name={"n_name"}
                    key={`_name`}
                    required
                    value={data.className} 
                    onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CLASS_NAME_CHANGED, payload: event.target.value})} 
                    placeholder="Enter name" />
                </Form.Group>
                <Form.Group controlId={`${data.type}_grade`}>
                    <Form.Label>Grade</Form.Label>
                    <Form.Control as="select" name={"n_grade"}
                    value={data.classGrade ? data.classGrade : ''} required
                    onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CLASS_GRADE_CHANGED, payload: event.target.value})} 
                    placeholder="Grade" >
                        {
                            [1,2,3,4,5,6,7,8,9,10,11,12].map((item, index) => (<option key={item} value={item}>{item}</option>))
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId={`${data.type}_backupTeacher`}>
                    <Form.Label>Backup teacher</Form.Label>
                    <Form.Control as="select" name={"n_backupTeacher"}
                    value={data.classBackupTeacher} required
                    onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CLASS_BACKUP_TECAHER_CHANGED, payload: event.target.value})} 
                    placeholder="Backup teacher" >
                        {
                            [ 
                                (<option key={"dummy"}>Select bacup teacher</option>),
                                ...data.availableBackupTeachers.map((item, index) => (<option key={item.id} value={item.id}>{item.name}</option>))
                            ]
                        }
                    </Form.Control>
                </Form.Group>
                <Form.Group controlId="validationCustom01">
                    <Form.Check type="checkbox"
                    checked={data.classAutomaticAnswerFlag}
                    onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CLASS_AUTOMATED_ANSWER_FLAG_CHANGED, payload: event.target.checked})}
                    label="Automatically answer students" />
                </Form.Group>
                <Form.Group>
                    { ClassStudents(classStudentsComponentRef, data.availableStudents, data.classStudents) }
                </Form.Group>
                <Button variant="primary" type="submit">
                    Save
                </Button>
            </Form>
        </div>
    )
}


const loadAvailableBackupTeachers = (dataDispatcher) => {
    (async function fn() {
        dataDispatcher({type: DATA_DISPATCHER_ACTION_START_LOADING_AVAILABLE_BACKUP_TEACHERS});
        try {
            const response = await request(`/webapp/get-school-backup-teachres`, 'POST', {});
            dataDispatcher({type: DATA_DISPATCHER_ACTION_AVAILABLE_BACKUP_TEACHERS_LOADED, payload: response.list});
        } catch(error) {
            dataDispatcher({type: DATA_DISPATCHER_ACTION_AVAILABLE_BACKUP_TEACHERS_LOADED_FAILED})
        }
    })();
}

  
const loadClassStudents = (dataDispatcher) => {
    (async function fn() {
        dataDispatcher({type: DATA_DISPATCHER_ACTION_START_LOADING_AVAILABLE_STUDENTS});
        try {
            const response = await request(`/webapp/manage/load-school-students`, 'POST', {});
            dataDispatcher({type: DATA_DISPATCHER_ACTION_AVAILABLE_STUDENTS_LOADED, payload: response});
        } catch(error) {
            dataDispatcher({type: DATA_DISPATCHER_ACTION_AVAILABLE_STUDENTS_LOAD_FAILED});
        }
    })();
}


const loadSelectedClassToForm = ({value: classId}, classAddEditFormDataChangeDispatcher) => {
    classAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_LOAD_CLASS});
    request(`/webapp/manage/load-class/${classId}`, 'POST', {}).then(response => {
        classAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CLASS_LOADED, payload: { data: response }})
    }).catch(error => {
        classAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CLASS_LOAD_FAILED})
    })
}


const ManageClasses = ({items, defaultTab, otherData}) => {
    
    const classesList = items.list;

    
    const dispatch = useDispatch();
    const studentsSelectElement = useRef(); 

    const [classAddEditFormData, classAddEditFormDataChangeDispatcher] = useReducer(addEditFormDataReducer, addEditFormDataReducerInitialState)

    useEffect(() => {
        loadClassStudents(classAddEditFormDataChangeDispatcher);
        loadAvailableBackupTeachers(classAddEditFormDataChangeDispatcher)
    }, []);

    useEffect(() => {
        if( MANAGE_ACTION_TAB_CLASS_EDIT === defaultTab && otherData && otherData.editClassId ) {
            loadSelectedClassToForm({value: otherData.editClassId}, classAddEditFormDataChangeDispatcher);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherData])

    const selectRef = useCallback(node => {
        if (node !== null) {
            if(node.options.length) {
                studentsSelectElement.current = node;
                applyMultiOnClassStudents(node)
            }
        }
      }, []);


    const classAddEditFormSubmithandler = (event) => {
        event.preventDefault();
        event.stopPropagation();
        const data = {
            ...classAddEditFormData,
            classStudents: Object.fromEntries(Array.from(studentsSelectElement.current.selectedOptions, option => option.value).map((entry) => [entry, entry]))
        }
        dispatch(addEditClass(data));
    }

    
    const AddEditClassBlockRenderer = (action) => {
        if(action === MANAGE_ACTION_TAB_CLASS_ADD) {
            return drawForm(selectRef, classAddEditFormData, classAddEditFormDataChangeDispatcher, classAddEditFormSubmithandler)
        } else {
            const selectedClassId = classAddEditFormData.selectedClassId ? classAddEditFormData.selectedClassId : '123';
            const selectClassDropdown = (
                <Select options={getClassOptionsFromReduxStoreItemsList(classesList)} 
                    key={"selectClass"}
                    name="selectClass" value={selectedClassId} 
                    onChange={value => loadSelectedClassToForm(value, classAddEditFormDataChangeDispatcher)} placeholder="Select class" />
            )

            if(classAddEditFormData.selectedClassId === null) {
                return (
                    <>
                        {selectClassDropdown}
                        <div key="loading">
                            Loading ...
                        </div>
                    </>
                )
            } else if(classAddEditFormData.selectedClassId === false) {
                return selectClassDropdown;
            } else {
                return (
                    <>
                        { selectClassDropdown }
                        { drawForm(selectRef, classAddEditFormData, classAddEditFormDataChangeDispatcher, classAddEditFormSubmithandler) }
                    </>
                )
            }
        }
    }
    


    if(!classAddEditFormData.availableBackupTeachers || !classAddEditFormData.availableStudents) {
        return (<div>Loading...</div>);
    }

    return (
        <ManageClassesContainer>
            <Tabs transition={false} onSelect={(key) => key !== MANAGE_ACTION_TAB_CLASS_EDIT ? classAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_RESET}): false } 
                defaultActiveKey={defaultTab}>
                <Tab eventKey={MANAGE_ACTION_TAB_CLASS_ADD} title="Create new Class">
                    {
                        AddEditClassBlockRenderer(MANAGE_ACTION_TAB_CLASS_ADD)
                    }
                </Tab>
                <Tab eventKey={MANAGE_ACTION_TAB_CLASS_EDIT} title="Edit class">
                    {
                        AddEditClassBlockRenderer(MANAGE_ACTION_TAB_CLASS_EDIT)
                    }
                </Tab>
            </Tabs>
        </ManageClassesContainer>
    );

}

export default ManageClasses;
