import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { apiCallBegan } from "./api";
import { getProblemSolvingSpentTime } from "./getters";
import { problemSubmissiondataUpdated } from "./items";
import { showToast } from "./message";

const slice = createSlice({
    name: 'work',
    initialState: { 
        answers: {},
        correctionStatuses: {},
        drawings: {},
        timeSpent: {},
        currentProblemWorkingStartTime: null,
        currentProblem: null,
        processingSubmission: false,
    },
    reducers: {
        problemAnswerChanged: (work, action) => {
            work.answers[action.payload.id] = action.payload.value;
        },
        correctionStatusSelected: (work, action) => {
            work.correctionStatuses[action.payload.id] = action.payload.value;
        },
        submissionProcessStarted: (work, action) => {
            work.processingSubmission = true;
        },
        submissionProcessFinished: (work, action) => {
            work.processingSubmission = false;

            //clean current problem time spent data
            work.timeSpent[work.currentProblem] = undefined;
            work.currentProblemWorkingStartTime = moment().unix(); //on next submission problem working time will be calculated from now
        },
        workingOnProblemStarted: (work, action) => {
            const now = moment().unix();
            const newSelectedProblemId = action.payload;
            const timeSpent = work.timeSpent;
            /**
             * IF WE HAVE ALREADy a selected problem we try to record that problem  working time
             */
            if(work.currentProblem) {
                //if time spent is greater or equals 1 second we record that time for specified problem, other wise we skip
                if(now - work.currentProblemWorkingStartTime > 0) {
                    if(!timeSpent[work.currentProblem]) {
                        timeSpent[work.currentProblem] = 0;
                    }

                    /**
                     * add time spent to problem
                     */
                    timeSpent[work.currentProblem] =  timeSpent[work.currentProblem] + (now - work.currentProblemWorkingStartTime);
                    work.timeSpent = timeSpent;
                }
            }

            /**
             * change selected problem and fix its working start time 
             */
            work.currentProblem = newSelectedProblemId;
            work.currentProblemWorkingStartTime = now;
            
        },
        resetWork: (work) => {
            work.answers = {};
            work.correctionStatuses = {};
            work.drawings = {};
            work.processingSubmission = false;
            work.timeSpent = {};
            work.currentProblemWorkingStartTime = null;
            work.currentProblem = null;
        }
    }
});

export default slice.reducer;
export const { workingOnProblemStarted, resetWork, correctionStatusSelected, problemAnswerChanged, submissionProcessStarted, submissionProcessFinished } = slice.actions;


// Action creators 

/**
 * @param {*} id 
 * @param {*} value 
 * @param {*} isTeacher 
 * @param {*} studentId if teacher review submission studentId should be passed
 */
export const submitProblem = (id, value, isTeacher = false, studentId = null) => (dispatch, getState) => {

    const url = isTeacher ? '/webapp/review-submission' : '/webapp/submit-work';
    const data = { id: id, answer: value };
    if(isTeacher) {
        data.studentId = studentId;
    } else {
        const { work } = getState()
        data['timeSpent'] = getProblemSolvingSpentTime(work, id);
    }


    return dispatch(apiCallBegan({
        url,
        method: 'post',
        data,
        onStart: submissionProcessStarted.type,
        onSuccess: [
            problemSubmissiondataUpdated.type, showToast.type, submissionProcessFinished.type
        ],
        onError: submissionProcessFinished.type,
        onFail: submissionProcessFinished.type
        // onStart: userLogInStart.type
    }));
}


