import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {canViewSolution, getSolutionUrl, isSubmissionAnswered, isSubmissionRightAnswered, getWorkOrSubmissionCorrectionStatus, getWorkOrSubmissionUserAnswer, isProblemActable, getSelectedProblem, getProblemChoicAnswersMap, canSubmitProblem, canProblemAnswerChanged} from '../lib/store/getters';
import { POBLEM_TYPE_CHOICE, POBLEM_TYPE_TEXT, toogleSelectedProblemSolutionVisibility, toogleSelectedProblemSubmissionLogVisibility } from '../lib/store/items';
import { problemAnswerChanged, correctionStatusSelected, submitProblem} from "../lib/store/work";
import { faCircle } from '@fortawesome/free-regular-svg-icons'
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Form, Overlay } from "react-bootstrap";
import { isTeacherUser } from '../lib/store/getters';
import { CORRECTION_STATUSES } from "../lib/constants";
import { getCorrectionStatusIcon } from "../lib/iconsHelper";
import Tooltip from 'react-bootstrap/Tooltip'
import { useRef } from "react";

const AnswerItem = styled.button`

    background-color: transparent;
    border-radius: 0;

    &.selected {
        border-radius: 3px;
        background-color: white;
    }

    &.gray {
        background-color: #cfcfcf;
    }

    &.right {

    }

    border: 0;
    position: relative;
    font-size: 12px;
    padding: 5px 15px;
    &:focus {
        border: 0;
        outline: none;
    };
`;

const RightAnswerIcon = styled(FontAwesomeIcon)`
    position: absolute;
    font-size:2em;
    top: 2px;
    left: 7px;
    color: #39de3f;

    &.hint {
        color: #239c28;
    }
`;

const AnswerItemsContainer = styled.div`
    background-color: #ad53d9;
    padding: 0 0.5em;
    height: 37px;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &:focus {
        border: 0;
        outline: none;
    }
    
`;

const CorrectionStatusItem = styled.button`
    background-color: transparent;
    border-radius: 0;
    font-size: 1em;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0px;
    margin: 0.2em;
    &:focus {
        border: 0;
        outline: none;
    };

    &.selected {
        border-radius: 5px;
        background-color: white;
    }

`;

const SubmissionButton = styled.button`
    background-color: #605dec;
    height: 30px;
    border: 0;
    border-radius: 4px;
    margin-left: 10px;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    &:focus {
        border: 0;
        outline: none;
    }
`;

const Container = styled.div`
    height: 50px;
    padding: 0;
    background-color: white;
    flex-shrink: 0;
    width: 100%;
    display: flex;
    align-items: center;
    border-top: 1px solid #e5e3f0;
    padding-left: 10px;
`;

const CorrectionStatusContainer = styled.div`
    background-color: #e6e6e6;
    border-radius: 6px;
    display: flex;
    height: 1.8em;

    margin-left: 50px;

`;

const LogViewTooltip = styled(Tooltip)`
    margin-bottom: 10px;

    .tooltip-inner {
        max-width: 300px;
        text-align: left;
    }
`;

const ActionCheckbox = styled(Form.Check)`
    margin-left:1em;
`;


const CorrectionStatusFontAwesomeIcon = styled(FontAwesomeIcon) `{
    font-size: 1em;
    width: 10px;
    &.correct {
        color: #78bf78;
    }
    
    &.incorrect {
        color: #ef4b4a;
    }

    &.incomplete {
        color: #ffbe00;
    }
}`;

const StyledTextAnswerViewBlock = styled.div`

`;


const StyledTextAnswerInputBlock = styled.div`

`;

const ChoiceAnswersBlock = ({user, work, items, dispatch}) => {
    
    const selectedProblem = getSelectedProblem(items);

    const problemRightAnswer = selectedProblem.rightAnswer;
    const problemId = selectedProblem.id;

    const selectedAnswer = getWorkOrSubmissionUserAnswer(work, selectedProblem);
    const isRightAnswered = isSubmissionRightAnswered(selectedProblem);
    const answersArray = getProblemChoicAnswersMap(selectedProblem) 

    const isTeacher = isTeacherUser(user);

    const answersMapElementInner = answersArray.map((item, index) => 
    {
        const answerClasses = [];
        let answerItemIcon = (<></>)

        if(selectedAnswer === index ) {
            answerClasses.push('selected')
            if(isTeacher) {
                answerClasses.push('gray');
                if(isRightAnswered || problemRightAnswer === index /** for teacher we show right answer hint also in case when auto answer is off and isRightAnswered is not set but the student seems to answered correctly */) {
                    answerItemIcon = <RightAnswerIcon key={index} className="hint" icon={faCircle}/>;
                }
            } else if(isRightAnswered) {
                answerItemIcon = <RightAnswerIcon key={index} icon={faCircle}/>;
            }
        } else {
            if(isTeacher && problemRightAnswer === index) {
                answerItemIcon = <RightAnswerIcon key={index} className="hint" icon={faCircle}/>;
            }
        }

        return (
            <AnswerItem key={index}
                    className={answerClasses.join(' ')}
                    onClick={() => canProblemAnswerChanged(user, selectedProblem) ? dispatch(problemAnswerChanged({id: problemId, value: index})) : true }>
                {answerItemIcon} {item}
            </AnswerItem>
        );
    });

    return (
        <>
            <AnswerItemsContainer key={"answeItemContainer"}>
                {answersMapElementInner}
            </AnswerItemsContainer>
            {canSubmitProblem(user, selectedProblem) ? 
                (<SubmissionButton key={"submitButton"} onClick={() => dispatch(submitProblem(problemId, selectedAnswer, isTeacher))}>
                    Submit</SubmissionButton>
                ) : (<></>)
            }
        </>
    );
}

const TextAnswersBlock = ({user, work, items, dispatch}) => {  
    const selectedProblem = getSelectedProblem(items);
    const problemId = selectedProblem.id;
    const selectedAnswer = getWorkOrSubmissionUserAnswer(work, selectedProblem);
    const isTeacher = isTeacherUser(user);
    if(canProblemAnswerChanged(user, selectedProblem)) {
        return (
            <StyledTextAnswerInputBlock>
            <input key="input" onChange={(event) => canProblemAnswerChanged(user, selectedProblem) ? 
                dispatch(problemAnswerChanged({id: problemId, value: event.target.value})) : true} 
                type={"text"} value={getWorkOrSubmissionUserAnswer(work, selectedProblem) || ''}/>
            <SubmissionButton key={"submit"} onClick={() => dispatch(submitProblem(problemId, selectedAnswer, isTeacher))}>
                    Submit</SubmissionButton>
            </StyledTextAnswerInputBlock>
        );
    } else if(!isTeacher) {
        return (
            <StyledTextAnswerViewBlock>
                Your answer: {selectedAnswer}
            </StyledTextAnswerViewBlock>
        );
    } else if(selectedAnswer) {
        return (
            <StyledTextAnswerViewBlock>
                Student answer: {selectedAnswer}
            </StyledTextAnswerViewBlock>
        );
    } else {
        return (<></>);
    }
    
}

const TeacherCorrectionStatusBlock = ({dispatch, problemId, selectedStudentId, selectedCorrectionStatus}) => 
(
    <>
        <CorrectionStatusContainer key={"CorrectionStatusContainer"}>
            {
                CORRECTION_STATUSES.map((correctionStatus) => {
                    return (
                        <>
                            <CorrectionStatusItem key={correctionStatus}
                                className = {selectedCorrectionStatus === correctionStatus ? 'selected': ''}
                                onClick = {() => dispatch(correctionStatusSelected({id: problemId, value: correctionStatus}))}>
                                    <CorrectionStatusFontAwesomeIcon className={correctionStatus} icon={getCorrectionStatusIcon(correctionStatus)} />
                            </CorrectionStatusItem>
                        </>
                    )
                })
            }
        </CorrectionStatusContainer>
        <SubmissionButton key={"SubmissionButton"} onClick={() => dispatch(submitProblem(problemId, selectedCorrectionStatus, true, selectedStudentId))}>
            Submit
        </SubmissionButton>
    </>
)

const SubmittionLogView = ({dispatch, problem}) => {
    const target = useRef(null);

    const { submissionLogVisible = false, submission = {} } = problem;

    const submissionLogView = (
        <>
            <div>Time spent (total): {submission.timeSpent}</div>
            <div>Tries count: {submission.submitTriesCount}</div>
            <div>First submitted: {submission.studentFirstSubmitTime}</div>
            <div>Last submitted: {submission.studentLastSubmitTime}</div>
        </>
    );
  
    return (
      <>
        <ActionCheckbox ref={target}
            checked={ submissionLogVisible } 
            onChange={()=>dispatch(toogleSelectedProblemSubmissionLogVisibility())} 
            type="checkbox" label="Submission log" />
       
        <Overlay target={target.current} show={submissionLogVisible} placement="top">
            {(props) => (  
                <LogViewTooltip {...props}>
                    {submissionLogView}
                </LogViewTooltip>
            )}
        </Overlay>
      </>
    );
}
  

const MainActionsBlock = () => {

    let actionsInner = [];

    const dispatch = useDispatch();
    const items = useSelector((state) => state.items);
    const { selectedStudentId } = items;
    const selectedProblem = getSelectedProblem(items);

    const work = useSelector((state) => state.work);
    const user = useSelector((state) => state.user);

    const isTeacher = isTeacherUser(user);
    

    if(selectedProblem && isProblemActable(selectedProblem)) {

        if(work.processingSubmission) {
            actionsInner.push(<div key={122}>Submitting</div>);
        } else {

            if(selectedProblem.answerType === POBLEM_TYPE_CHOICE) {
                actionsInner.push(
                    <ChoiceAnswersBlock key={"answerBlock"} dispatch={dispatch} 
                                user = {user} work = {work} items = {items} />
                );
            } else if(selectedProblem.answerType === POBLEM_TYPE_TEXT) {
                actionsInner.push(
                    <TextAnswersBlock key={"answerBlock"} dispatch={dispatch} 
                                user = {user} work = {work} items = {items} />
                );
            }

            if(canViewSolution(selectedProblem) && getSolutionUrl(selectedProblem)) {
                actionsInner.push(
                    <ActionCheckbox key={"ShowSolution"} checked={selectedProblem.solutionVisible !== undefined && selectedProblem.solutionVisible} 
                        onChange={()=>dispatch(toogleSelectedProblemSolutionVisibility({}))} type="checkbox" label="Show solution" />
                )
            }


            //showToast

            /**
             * if teacher, show correction status block
             */
            if(isTeacher && isSubmissionAnswered(selectedProblem)) {
                actionsInner.push(<TeacherCorrectionStatusBlock key={103} selectedStudentId={selectedStudentId} dispatch = {dispatch} 
                    problemId = {selectedProblem.id} 
                    selectedCorrectionStatus={ getWorkOrSubmissionCorrectionStatus(work, selectedProblem) }/>)

                actionsInner.push(
                    (<SubmittionLogView dispatch={dispatch} problem={selectedProblem}/>));
            }
        }

        return (<Container>{actionsInner}</Container>);
    }

    return (<></>);
};

export default MainActionsBlock;