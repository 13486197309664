import { Alert, Button, Form, FormFile, Tab, Tabs } from "react-bootstrap";
import { useEffect, useReducer } from "react";
import styled from "styled-components";
import { request } from "../lib/request";
import Select from 'react-select'
import { useDispatch } from "react-redux";
import { addEditContainer, addEditProblem, deleteSolution } from "../lib/store/manage";
import { MANAGE_ACTION_TAB_BULK_UPLOAD_PROBLEMS, MANAGE_ACTION_TAB_PROBLEM_ADD, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT, backendHost } from "../lib/constants";
import { getcurrentContainerItemsType as getCurrentContainerItemsType, getCurrentNavigationContainerType, getlistItemById} from "../lib/store/getters";
import { ITEM_TYPE_CLASS, ITEM_TYPE_CONTAINER, ITEM_TYPE_PROBLEM } from "../lib/store/items";
import { ClassContainerBulkUploadComponent } from "./bulkUpload";


const ManageContainerHtmlWrapper = styled.div`
    margin: 30px auto;
    width: 90%;
    padding: 0px 80px;


    .tab-content {
        overflow: scroll;
        height: 95%;
    }

    .multi-wrapper .header {
        position: absolute;
        top: 45px;
    }

    .non-selected-wrapper, .selected-wrapper {
        margin-top:40px;
        
    }
    
    .multi-wrapper {
        position: relative;
    }

    .multi-wrapper .non-selected-wrapper, .multi-wrapper .selected-wrapper {
        height: 300px !important;
    }

    .btn-container {
        text-align: center;
        margin-top: 20px;
    }

    .create-new-item {
        margin-top: 20px;
    }

    .pr-image img {
        display: block;
        max-width: 300px;
        border: 1px solid grey;
        padding: 10px;
        margin: 5px;
    }

    .drop-zone .dropping {
        min-height: 100px;
        background-color: #aaa;
        margin: 20px;
        padding: 30px;    
    }


    .drop-zone .waiting {
        min-height: 100px;
        background-color: #DDD;
        margin: 20px;
        padding: 30px;    
    }

`;

const getContainerOptionsFromReduxStoreItemsList = (list) => {
    const options = [];
    for (const [key, value] of Object.entries(list)) {
        options.push({label: value.name, value: key});
    }

    return options;
}



/**
 * CONTAINER ADD EDIT DATA REDUCER PART
 */

const DATA_DISPATCHER_ACTION_RESET = 'reset';
const DATA_DISPATCHER_ACTION_LOAD_ITEM = 'load-container';
const DATA_DISPATCHER_ACTION_CONTAINER_LOADED = 'container-loaded';
const DATA_DISPATCHER_ACTION_ITEM_LOAD_FAILED = 'container-load-failed';
const DATA_DISPATCHER_ACTION_ITEM_NAME_CHANGED = 'name';
const DATA_DISPATCHER_ACTION_ITEM_STATUS_CHANGED = 'status';
const DATA_DISPATCHER_ACTION_ITEM_DESCRIPTION_CHANGED = 'description';
const DATA_DISPATCHER_ACTION_ITEM_IMAGE_CHANGED = 'image';
const DATA_DISPATCHER_ACTION_ITEM_ANSWER_TYPE_CHANGED = 'answer_type'; 
const DATA_DISPATCHER_ACTION_ITEM_ANSWERS_COUNT_CHANGED = 'answers_count'; 
const DATA_DISPATCHER_ACTION_ITEM_RIGHT_ANSWER_CHANGED = 'right_answer'; 
const DATA_DISPATCHER_ACTION_ITEM_CHOICE_ANSWER_SHOW_TYPE_CHANGED = 'choice_answer_show_type'; 
const DATA_DISPATCHER_ACTION_ITEM_PROBLEM_TYPE_CHANGED = 'problem_type'; 
const DATA_DISPATCHER_ACTION_ITEM_SOLUTION_CHANGED = 'solution'; 


const addEditFormDataReducerInitialState = {
    type: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD,
    selectedItem: false, //initial value is false, when starting to load the value is changing to null, after finishing to load the state either is changed to false again (if error happened) or the value of selected container id
    // containerName: '',
    // containerStatusFlag: false
}

const addEditFormDataReducer = (state, action) => {


    switch(action.type) {
        case DATA_DISPATCHER_ACTION_RESET: 
            
            const l = {
                ...addEditFormDataReducerInitialState,
                availableBackupTeachers: state.availableBackupTeachers //keep availableBackupTeachers value, we load it once, per component mount
            };

            return l;
        case DATA_DISPATCHER_ACTION_LOAD_ITEM: 
            return {
                ...state,
                type: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT,
                selectedItem: null
            }
        case DATA_DISPATCHER_ACTION_CONTAINER_LOADED:
            return {
                ...state,
                type: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT,
                selectedItem: action.payload.data,
            }
        case DATA_DISPATCHER_ACTION_ITEM_LOAD_FAILED: 
            return {
                ...state,
                type: MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT,
                selectedItem: false
            }
        
        //edit actions
        case DATA_DISPATCHER_ACTION_ITEM_NAME_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_IMAGE_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_STATUS_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_ANSWERS_COUNT_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_RIGHT_ANSWER_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_DESCRIPTION_CHANGED:
        case DATA_DISPATCHER_ACTION_ITEM_SOLUTION_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_PROBLEM_TYPE_CHANGED: 
        case DATA_DISPATCHER_ACTION_ITEM_CHOICE_ANSWER_SHOW_TYPE_CHANGED: 
            return {
                ...state,
                selectedItem: {
                    ...state.selectedItem,
                    [action.type]: action.payload
                }
            }
        
        case DATA_DISPATCHER_ACTION_ITEM_ANSWER_TYPE_CHANGED:
            
            const dt = {
                ...state,
                selectedItem: {
                    ...state.selectedItem,
                    [DATA_DISPATCHER_ACTION_ITEM_ANSWER_TYPE_CHANGED]: action.payload
                }
            }
            

            if(!state.selectedItem[DATA_DISPATCHER_ACTION_ITEM_CHOICE_ANSWER_SHOW_TYPE_CHANGED]) {
                //if answer showing type is not set, set default
                dt.selectedItem[DATA_DISPATCHER_ACTION_ITEM_CHOICE_ANSWER_SHOW_TYPE_CHANGED] = 'letters';
                
            }

            return dt;

    
        default:
            throw Error("Invalid reducer action");
    }
}

/**
 * END CONTAINER ADD EDIT DATA REDUCER PART
 */


const drawForm = (dispatch, items, type, data, dataChangeDispatcher, submitHandler) => {

    const errorMessage = data.errorMessage;

    if( type === ITEM_TYPE_CONTAINER ) {
        return (
            <div key={"form"} className="create-new-item">
                {errorMessage ? 
                    (<Alert key={"error"} variant={"danger"}>
                        {errorMessage}
                    </Alert>) : (<></>)}
                <Form key="Form" noValidate validated={data.validated} onSubmit={submitHandler}>
                    <Form.Group key={`${data.type}_name`} controlId={`${data.type}_name`}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name={"n_name"}
                        key={`_name`}
                        required
                        value={data.selectedItem?.name ?? ''} 
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_NAME_CHANGED, payload: event.target.value})} 
                        placeholder="Enter name" />
                    </Form.Group>
                    <Form.Group controlId="validationCustom01">
                        <Form.Check type="checkbox"
                        checked={data.selectedItem?.status ?? ''}
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_STATUS_CHANGED, payload: event.target.checked})}
                        label="Enabled" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
            </div>
        )
    } else {

        const hasSolution = data.selectedItem?.solution_url;        
        

        // const choiceAnswers = {
        //     'letters' : [
        //         'A', 'B', 'C', 'D', 'E', 'F', 'G'
        //     ], 
        //     'numbers' : [1,2,3,4,5,6]
        // }

        // if(data.selectedItem?.answer_type === 'choice') {
        //     const rightAnswerOptions = choiceAnswers[data.selectedItem?.choice_answer_show_type].map((item, index) => (<option key={item} value={item}>{item}</option>))
        // }

        return (
            <div key={"form"} className="create-new-item">
                {errorMessage ? 
                    (<Alert key={"error"} variant={"danger"}>
                        {errorMessage}
                    </Alert>) : (<></>)}
                <Form key="Form" noValidate validated={data.validated} onSubmit={submitHandler}>
                <Form.Group key={`${data.type}_problem_type`} controlId={`${data.type}_problem_type`}>
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name={"n_problem_type"}
                        key={`_problem_type`}
                        required
                        value={data.selectedItem?.problem_type ?? ''} 
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_PROBLEM_TYPE_CHANGED, payload: event.target.value})} 
                        placeholder="Enter description" >
                                    <option>Select</option>
                                    <option value="passage">Passage</option>
                                    <option value="test">Problem</option>
                            </Form.Control>
                    </Form.Group>
                    <Form.Group key={`${data.type}_name`} controlId={`${data.type}_name`}>
                        <Form.Label>Name</Form.Label>
                        <Form.Control type="text" name={"n_name"}
                        key={`_name`}
                        required
                        value={data.selectedItem?.name ?? ''} 
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_NAME_CHANGED, payload: event.target.value})} 
                        placeholder="Enter name" />
                    </Form.Group>
                    <Form.Group key={`${data.type}_description`} controlId={`${data.type}_description`}>
                        <Form.Label>Description</Form.Label>
                        <Form.Control type="text" name={"n_description"}
                        key={`_description`}
                        required
                        value={data.selectedItem?.description || ''} 
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_DESCRIPTION_CHANGED, payload: event.target.value})} 
                        placeholder="Enter description" />
                    </Form.Group>
                    <Form.Group key={`${data.type}_image`} className="pr-image" controlId={`${data.type}_image`}>

                        <FormFile>
                            <FormFile.Label>Problem image</FormFile.Label>
                                {
                                    (data.selectedItem?.image_url ? (
                                        <img alt="" src = { `${backendHost}${data.selectedItem.image_url}` } />
                                    ) : (<></>))
                                }
                                <FormFile.Input 
                                    onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_IMAGE_CHANGED, payload: event.target.files[0]})}
                                     />
                        </FormFile>
                    </Form.Group>
                    <Form.Group key={`${data.type}_answer_type`} controlId={`${data.type}_answer_type`}>
                        <Form.Label>Answer type</Form.Label>
                        <Form.Control as="select" name={"n_answer_type"}
                        key={`answer_type`}
                        required
                        value={data.selectedItem?.answer_type ?? ''} 
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_ANSWER_TYPE_CHANGED, payload: event.target.value})} 
                        placeholder="Enter description" >
                                    <option>Select</option>
                                    <option value="choice">Choice</option>
                                    <option value="text">Text answer</option>
                                    <option value="no_answer">No answer</option>
                            </Form.Control>
                    </Form.Group>
                    {data.selectedItem?.answer_type === 'choice' && 
                        <Form.Group key={`${data.type}_answers_count`} controlId={`${data.type}_answers_count`}>
                            <Form.Label>Answers count</Form.Label>
                            <Form.Control type="text" name={"n_answers_count"}
                            key={`_answers_count`}
                            required
                            value={data.selectedItem?.answers_count ?? ''} 
                            onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_ANSWERS_COUNT_CHANGED, payload: event.target.value})} 
                            placeholder="Enter answers count" />
                        </Form.Group>
                    }
                    {data.selectedItem?.answer_type === 'choice' && 
                        <Form.Group key={`${data.type}_right_answer`} controlId={`${data.type}_right_answer`}>
                            <Form.Label>Right answer</Form.Label>
                            <Form.Control as="select" name={"n_right_answer"}
                            key={`right_answer`}
                            required
                            value={data.selectedItem?.right_answer ?? ''} 
                            onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_RIGHT_ANSWER_CHANGED, payload: event.target.value})} 
                            placeholder="Enter description" >
                                        <option>Select</option>
                                        <option value="0">A</option>
                                        <option value="1">B</option>
                                        <option value="2">C</option>
                                        <option value="3">D</option>
                                        <option value="4">E</option>
                                </Form.Control>
                        </Form.Group>
                    }
                    {data.selectedItem?.answer_type === 'choice' &&
                        <Form.Group key={`${data.type}_choice_answer_show_type`} controlId={`${data.type}_choice_answer_show_type`}>
                            <Form.Label>Choice answer showing type</Form.Label>
                            <Form.Control as="select" name={"n_choice_answer_show_type"}
                            key={`choice_answer_show_type`}
                            required
                            value={data.selectedItem?.choice_answer_show_type ?? ''} 
                            onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_CHOICE_ANSWER_SHOW_TYPE_CHANGED, payload: event.target.value})} 
                            placeholder="Enter choice answer showing type" >
                                        <option>Select</option>
                                        <option value="letters">letters</option>
                                        <option value="numbers">numbers</option>
                                </Form.Control>
                        </Form.Group>
                    }
                    <Form.Group key={`${data.type}_solution`} className="pr-image" controlId={`${data.type}_solution`}>
                        <FormFile>
                            <FormFile.Label>Solution {hasSolution ? ((<button onClick={() => dispatch(deleteSolution(items, data.selectedItem.id))}>delete</button>)) : (<></>)}</FormFile.Label>
                                {
                                    (data.selectedItem?.solution_url ? (
                                        <img alt="" src = { `${backendHost}${data.selectedItem.solution_url}` } />
                                    ) : (<></>))
                                }
                                <FormFile.Input 
                                    onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_SOLUTION_CHANGED, payload: event.target.files[0]})}
                                    />
                        </FormFile>
                    </Form.Group>
                    <Form.Group controlId="validationCustom01">
                        <Form.Check type="checkbox"
                        checked={data.selectedItem?.status ?? ''}
                        onChange={(event) => dataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_STATUS_CHANGED, payload: event.target.checked})}
                        label="Enabled" />
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Save
                    </Button>
                </Form>
            </div>
        )
    }
}



const loadSelectedItemToForm = ({value: item}, itemAddEditFormDataChangeDispatcher) => {
    itemAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_LOAD_ITEM});

    const url = (ITEM_TYPE_CONTAINER === item.type) ? `/webapp/manage/load-container/${item.id}` : `/webapp/manage/load-problem/${item.id}`;

    request(url, 'POST', {}).then(response => {
        itemAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_CONTAINER_LOADED, payload: { data: response }})
    }).catch(error => {
        itemAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_ITEM_LOAD_FAILED})
    })
}



// const AddProbemsBulkUpload = (items, dispatch) => {
//     return (<>
//         <br key="br"/>
//         <div key="problems">
//             <div key="title">Uplaod problems here</div>
//             <BulkUploadComponent key="component" type={BULK_UPLOAD_TYPE_PROBLEMS}  items={items} dispatch={dispatch}/>
//         </div>
//         <hr key="hr"/>
//         <div key="solutions">
//             <div key="title">Uplaod solutions here</div>
//             <BulkUploadComponent key="component" type={BULK_UPLOAD_TYPE_SOLUTIONS}  items={items} dispatch={dispatch}/>
//         </div>
//     </>)
// }


const ManageContainers = ({items, defaultTab, otherData}) => {
      
    const dispatch = useDispatch();

    const [itemAddEditFormData, itemAddEditFormDataChangeDispatcher] = useReducer(addEditFormDataReducer, {...addEditFormDataReducerInitialState})
    const currentContainerSubContaiersType = getCurrentContainerItemsType(items);
    
    
    useEffect(() => {
        if( MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT === defaultTab && otherData && otherData.selectedItem ) {
            loadSelectedItemToForm({value: otherData.selectedItem}, itemAddEditFormDataChangeDispatcher);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [otherData])

    const containerAddEditFormSubmithandler = (itemType) => {
        return (event) => {

            event.preventDefault();
            event.stopPropagation();
            const data = {
                ...itemAddEditFormData,
            }
            
            if(ITEM_TYPE_PROBLEM === itemType) {
                dispatch(addEditProblem(data));
            } else {
                dispatch(addEditContainer(data));
            }
            itemAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_RESET});
        }
    }
    
    const AddEditItemBlockRenderer = (action) => {   

        const _itemType = currentContainerSubContaiersType ?? ITEM_TYPE_CONTAINER;


        if(action === MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD) {
            return drawForm(dispatch, items, _itemType, itemAddEditFormData, itemAddEditFormDataChangeDispatcher, containerAddEditFormSubmithandler(_itemType))
        } else if(action === MANAGE_ACTION_TAB_PROBLEM_ADD) {

            return drawForm( dispatch, items, ITEM_TYPE_PROBLEM, itemAddEditFormData, itemAddEditFormDataChangeDispatcher, containerAddEditFormSubmithandler(ITEM_TYPE_PROBLEM))
        } else {
            const selectedItem = itemAddEditFormData.selectedItem;
            
            const selectContainerDropdown = (
                <Select options={getContainerOptionsFromReduxStoreItemsList(items.list)} 
                    key={"selectItem"}
                    name="selectItem" value={selectedItem} 
                    onChange={value => loadSelectedItemToForm(getlistItemById(items, value), itemAddEditFormDataChangeDispatcher)} placeholder="Select item" />
            )
            

            if(itemAddEditFormData.selectedItem === null) {
                return (
                    <>
                        {selectContainerDropdown}
                        <div key="loading">
                            Loading ...
                        </div>
                    </>
                )
            } else if(itemAddEditFormData.selectedItem === false) {
                return selectContainerDropdown;
            } else {
                return (
                    <>
                        { selectContainerDropdown }
                        { drawForm(dispatch, items, _itemType, itemAddEditFormData, itemAddEditFormDataChangeDispatcher, containerAddEditFormSubmithandler(_itemType)) }
                    </>
                )
            }
        }
    }

    

    const tabs = [];

    const navigationContainerType = getCurrentNavigationContainerType(items);

    if(currentContainerSubContaiersType === ITEM_TYPE_CONTAINER) {
        tabs.push(
            (
                <Tab key="Create new container" eventKey={MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD} title="Create new container">
                    {
                        AddEditItemBlockRenderer(MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD)
                    }
                </Tab>
            )
        );
        tabs.push(
            (
                <Tab key="Edit container" eventKey={MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT} title="Edit container">
                    {
                        AddEditItemBlockRenderer(MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT)
                    }
                </Tab>
            )
        );
    } else if(currentContainerSubContaiersType === ITEM_TYPE_PROBLEM) {
        tabs.push(
            (
                <Tab key="Create new problem" eventKey={MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD} title="Create new problem">
                    {
                        AddEditItemBlockRenderer(MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD)
                    }
                </Tab>
            )
        );
        tabs.push(
            (
                <Tab key="Edit problem" eventKey={MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT} title="Edit problem">
                    {
                        AddEditItemBlockRenderer(MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT)
                    }
                </Tab>
            )
        );

    } else {
        tabs.push(
            (
                <Tab key="Create new container" eventKey={MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD} title="Create new container">
                    {
                        AddEditItemBlockRenderer(MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD)
                    }
                </Tab>
            )
        );

        if(navigationContainerType === ITEM_TYPE_CONTAINER) {
            tabs.push(
                (
                    <Tab key="Create new problem" eventKey={MANAGE_ACTION_TAB_PROBLEM_ADD} title="Create new problem">
                        {
                            AddEditItemBlockRenderer(MANAGE_ACTION_TAB_PROBLEM_ADD)
                        }
                    </Tab>
                )
            );
        }

    }

    const cntType = getCurrentNavigationContainerType(items);
    console.log(currentContainerSubContaiersType);
    console.log(cntType);


    if(cntType === ITEM_TYPE_CLASS || (cntType === ITEM_TYPE_CONTAINER && currentContainerSubContaiersType !== ITEM_TYPE_PROBLEM)) {
        tabs.push(
            (
                <Tab key="Bulk upload3" eventKey={MANAGE_ACTION_TAB_BULK_UPLOAD_PROBLEMS} title="Class/container problems bulk upload">
                    <ClassContainerBulkUploadComponent key="component" items={items} dispatch={dispatch}/>
                </Tab>
            )
        );
    }


    return (
        <ManageContainerHtmlWrapper>
            <Tabs transition={false} onSelect={(key) => key !== MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT ? itemAddEditFormDataChangeDispatcher({type: DATA_DISPATCHER_ACTION_RESET}): false } 
                defaultActiveKey={defaultTab}>
                    {tabs}
            </Tabs>
        </ManageContainerHtmlWrapper>
    );

}

export default ManageContainers;
