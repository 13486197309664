import { isProblemSeen, isStudentUser } from "./getters";
import { changeProblemStatusSeen, navigate } from "./items"
import { workingOnProblemStarted } from "./work";

/**
 * fires when problem selected
 */
export const problemItemClicked = (dispatch, user, problem) => {
    dispatch(navigate(problem));

    if(!isProblemSeen(problem)) {
        dispatch(changeProblemStatusSeen(problem.submission.id))
    }
    
    /**
     * we calculate only students time spents
     */
    if(isStudentUser(user)) {
        dispatch(workingOnProblemStarted(problem.id));
    }
}