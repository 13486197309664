import axios from "axios";
import * as actions from "../api";
import { showModal } from "../message";

import { userLogedOut } from '../user';
import { resetItems } from "../items";
import { RESPONSE_SERVER_ERROR_STATUSES, RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES, RESPONSE_FAILED_STATUSES, RESPONSE_SUCCEED_STATUSES, AUTHORIZATION_FAILED, backendHost } from '../../constants'
import { resetWork } from "../work";
import { resetManage } from "../manage";


const apiCallActionDispatcher = (onEvent, dispatch, defaultPayload = null) => {

  if (onEvent) {
    let onStartArray = onEvent;
    if(!Array.isArray(onEvent)) {
      onStartArray = [onEvent];
    }
      
    onStartArray.forEach((item) => {
      if(item.type && item.payload) {
          //we have passed payload from caller, lets pass to reducer
        dispatch({ type: item.type, payload: item.payload });
      } else {
        dispatch({ type: item, payload: defaultPayload});
      }
    });
    
  }
}


const api = ({ dispatch }) => next => async action => {
  if (action.type !== actions.apiCallBegan.type) return next(action);


  const { url, method, data, 
    onStart, //call this action before starting actual request
    onSuccess, //request succees callback
    onFail, //callback for request success with failure response due to user behaivior (ex. incorect username or password, incorrect field value, ...)
    onError //callback (rollback action) for reqiest error (due to selver behaivior, connection, client application)
  } = action.payload;


  apiCallActionDispatcher(onStart, dispatch)

  next(action);

  try {
    const response = await axios.request({
      baseURL: backendHost,
      url,
      method,
      withCredentials: true,
      data
    });


    const responseStatus = response.data.status;
    const responseData = response.data.result;

    if(AUTHORIZATION_FAILED === responseStatus) {
      dispatch(userLogedOut());
      dispatch(resetItems());
      dispatch(resetWork());
      dispatch(resetManage());
    } else {

      if(responseStatus >= RESPONSE_SUCCEED_STATUSES.start && 
            responseStatus < RESPONSE_SUCCEED_STATUSES.end) {
          dispatch(actions.apiCallSuccess(responseData));
          // Specific

          apiCallActionDispatcher(onSuccess, dispatch, responseData);

      } else if(responseStatus >= RESPONSE_FAILED_STATUSES.start && 
              responseStatus < RESPONSE_FAILED_STATUSES.end) {

          dispatch(actions.apiCallFailed(responseData));
          // Specific
          apiCallActionDispatcher(onFail, dispatch, responseData);

      } else if((responseStatus >= RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES.start && 
                    responseStatus < RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES.end) ||
              (responseStatus >= RESPONSE_SERVER_ERROR_STATUSES.start && 
                    responseStatus < RESPONSE_SERVER_ERROR_STATUSES.end)) {
          dispatch(actions.apiCallError(responseData));
          dispatch(showModal(response.data.message));
          // Specific
          if (onError) {
            apiCallActionDispatcher(onError, dispatch, responseData);
          }
      }
    }

  } catch (error) {
    // General
    const message = error.data ? error.data.message : error.message ? error.message : 'Unknown error!!!!!!!';
    dispatch(actions.apiCallError(message));
    dispatch(showModal(message));
    // Specific
    if (onError) {
      apiCallActionDispatcher(onError, dispatch, {});
    }
  }
};

export default api;
