import React, {useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logInUser } from '../lib/store/user';
import styled from "styled-components";
import image from '../res/images/login_bg.png'
import logo from '../res/images/logo.png'

const MainBlock = styled.div`
    display: flex;
    flex-direction: row;
    margin: 0 auto;
    width: 60%;
    justify-content: center;
`;

const FormBlock = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    width:50%;
`;

const BannerBlock = styled.div`
    display: flex;
    flex-direction: column;
    background-image:url('${image}');
    width:50%;
    background-color: #7278ef;
    background-size: cover;
    min-height: 400px;
    align-items: center;
    justify-content: center;
`;

const LocginFormBlock = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 10px auto;
`;

const BannerItemBlock1 = styled.div`
    color: white;
    font-size: 45px;
    font-weight: 400;
    text-align: center;
    margin: 20px auto;
`;

const BannerItemBlock2 = styled.div`
    color: white;
    font-size: 25px;
    font-weight: 400;
    text-align: center;
    margin: 20px auto;
`;





const LoginComponnet = () => {
    useSelector(state => state.user);
    const dispatch = useDispatch();


    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    
    
    // const handleSubmit = useCallback(
    //     (e) => {

    //         login(username, password ).then((result) => {
    //             dispatch({
    //                 action: ACTION_SET_USER_INFO,
    //                 payload: result
    //             });
    //         }).catch((err) => {
    //             dispatch({
    //                 action: ACTION_SET_USER_INFO,
    //                 payload: false
    //             });
    //         })
    //     },
    //     [username, password, dispatch],
    // )
    

    return (
        <MainBlock>
            <FormBlock>
                <form onSubmit={ () => dispatch(logInUser(username, password)) }>
                    <LocginFormBlock>
                        <input type="text" value={username} onChange={(e) => setUsername(e.target.value)}></input>
                    </LocginFormBlock>
                    <LocginFormBlock>
                        <input type="password" value={password} onChange={(e) => setPassword(e.target.value)}></input>
                    </LocginFormBlock>
                    <LocginFormBlock>
                        <input type="submit" value="Login"></input>
                    </LocginFormBlock>
                </form>
            </FormBlock>
            <BannerBlock>
                <BannerItemBlock1>K-12</BannerItemBlock1>
                <BannerItemBlock1><img alt="logo" src={logo}/></BannerItemBlock1>
                <BannerItemBlock2>Enrichment program</BannerItemBlock2>
            </BannerBlock>
        </MainBlock>
    );
}

export default LoginComponnet;