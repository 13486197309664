import axios from "axios";
import { RESPONSE_SERVER_ERROR_STATUSES, RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES, RESPONSE_FAILED_STATUSES, RESPONSE_SUCCEED_STATUSES, AUTHORIZATION_FAILED, backendHost } from "./constants";


export const request = async (url, method, data) => {

  try {
    const response = await axios.request({
      baseURL: backendHost,
      url,
      method,
      withCredentials: true,
      data
    });

    const responseStatus = response.data.status;
    const responseData = response.data.result;

    if(AUTHORIZATION_FAILED === responseStatus) {
      throw Error("Failed")
    } else {

      if(responseStatus >= RESPONSE_SUCCEED_STATUSES.start && 
            responseStatus < RESPONSE_SUCCEED_STATUSES.end) {
                return responseData;
 
      } else if(responseStatus >= RESPONSE_FAILED_STATUSES.start && 
              responseStatus < RESPONSE_FAILED_STATUSES.end) {
                throw Error(response.data.message)

      } else if((responseStatus >= RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES.start && 
                    responseStatus < RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES.end) ||
              (responseStatus >= RESPONSE_SERVER_ERROR_STATUSES.start && 
                    responseStatus < RESPONSE_SERVER_ERROR_STATUSES.end)) {
                      throw Error(response.data.message)
      }
    }

  } catch (error) {
    throw Error(error.message)
  }
}