const ClassStudents = (elementRef, schoolStudents, classStudents) => {

    const options = [];
    for (const [key, value] of Object.entries(schoolStudents)) {
        options.push(
            (
                <option key={key} value={key}>{value}</option>
            )
        );
    }

    return (
        <select key={"classStudents"} onChange = {() => {}} value={Object.keys(classStudents)} multiple={ true } ref={ elementRef }>
            { options }
        </select>
    );
}

export default ClassStudents;