export const backendHost = process.env.REACT_APP_BACKEND_API_URL;

export const CORRECTION_STATUS_CORRECT = 'correct';
export const CORRECTION_STATUS_INCORRECT = 'incorrect';
export const CORRECTION_STATUS_INCOMPLETE = 'incomplete';

export const CORRECTION_STATUSES = [CORRECTION_STATUS_CORRECT, CORRECTION_STATUS_INCORRECT, CORRECTION_STATUS_INCOMPLETE];

export const PROBLEM_TYPE_PASSAGE = 'passage';
export const PROBLEM_TYPE_TEST = 'test';

export const USER_ROLE_STUDENT = 'student'
export const USER_ROLE_TEACHER = 'teacher'

export const VIEW_OPERATIONAL = 'operational';
export const VIEW_MANAGE_CONTENT = 'manage_content';


export const ITEM_STATUS_DISABLED = -1;


export const AUTHORIZATION_FAILED = 203;


/**
 * All ranges have inclusive start
 */
export const RESPONSE_SUCCEED_STATUSES = {start: 100, end: 200};
export const RESPONSE_FAILED_STATUSES = {start: 200, end: 300};
export const RESPONSE_INVALID_OR_INCONSISTENT_REQUEST_DATA_STATUSES = {start: 300, end: 400};
export const RESPONSE_SERVER_ERROR_STATUSES = {start: 500, end: 600};


export const MANAGE_ACTION_TAB_CLASS_ADD = 'add_class';
export const MANAGE_ACTION_TAB_CLASS_EDIT = 'edit_class';
export const MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD = 'add_container_or_container';
export const MANAGE_ACTION_TAB_PROBLEM_ADD = 'add_problem';
export const MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_EDIT = 'edit_container';
export const MANAGE_ACTION_TAB_BULK_UPLOAD_PROBLEMS = 'bulk_upload';

export const BULK_UPLOAD_TYPE_PROBLEMS = 'tp_problems';
export const BULK_UPLOAD_TYPE_SOLUTIONS = 'tp_solutions';
export const BULK_UPLOAD_TYPE_CLASS_PROBLEMS_AND_SOLUTIONS = 'tp_pr_solutions';