import { createSlice } from "@reduxjs/toolkit";

export const MESSAGE_TYPE_MODAL_ALERT = 'modal';
export const MESSAGE_TYPE_MODAL_CONFIRMATION = 'confirmation';
export const MESSAGE_TYPE_TOAST = 'toast';

const slice = createSlice({
    name: 'message',
    initialState: { 
        body: null,
        type: null,
        data: {}
    },
    reducers: {
        showModal: (message, action) => {
            message.body = action.payload;
            message.type = MESSAGE_TYPE_MODAL_ALERT;
        },
        showConfirmation: (message, action) => {
            message.body = action.payload.body;
            message.data = 
            {
                onConfirmationAction: action.payload.onConfirmationAction
            }
            message.type = MESSAGE_TYPE_MODAL_CONFIRMATION;
        },
        hideMessage: (message, action) => {
            message.body = null;
            message.type = null;
            message.data = {};
        },
        showToast: (message, action) => {
            message.body = action.payload && action.payload.message ? action.payload.message : 'Successfuly submitted';
            message.type = MESSAGE_TYPE_TOAST;
        },
    }
});

export default slice.reducer;
export const { showModal, hideMessage, showToast, showConfirmation } = slice.actions;