import { faCheckCircle, faInfoCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons"
import { CORRECTION_STATUS_CORRECT, CORRECTION_STATUS_INCOMPLETE, CORRECTION_STATUS_INCORRECT } from "./constants";

export const getCorrectionStatusIcon = (correctionStatus) => {
    switch(correctionStatus) {
        case CORRECTION_STATUS_CORRECT:
            return faCheckCircle;
        case CORRECTION_STATUS_INCOMPLETE:
            return faInfoCircle;
        case CORRECTION_STATUS_INCORRECT:
        default:
            return faTimesCircle;
    }
}