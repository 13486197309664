import { createSlice } from "@reduxjs/toolkit";
import { MANAGE_ACTION_TAB_CLASS_ADD, MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD } from "../constants";
import { request } from "../request";
import { apiCallBegan } from "./api";
import { getCurrentNavigationContainerType, getPathLastContainerItem, getPathLastItem, isTeacherUser } from "./getters";
import { getLoadClassesAction, getLoadContainersAction, getLoadStudentsAction, ITEM_TYPE_CLASS, NAVIGATION_TYPE_GOTO } from "./items";
import { hideMessage } from "./message";

const slice = createSlice({
    name: 'manage',
    initialState: { 
        addNewUserToClassViewVisible: false,
        managemenetVisibleTab: null,
        managementOtherData: null
    },
    reducers: {
        resetManage: (manage, action) => {
            manage.addNewUserToClassViewVisible = false;
            manage.managemenetVisibleTab = null;
            manage.managementOtherData = null;
        },
        classStudentsManagementShown: (manage, action) => {
            manage.addNewUserToClassViewVisible = true
        },
        addNewUserToClassViewHidden: (manage, action) => {
            manage.addNewUserToClassViewVisible = false
        },
        addEditClassViewShown: (manage, action) => {
            if(!action.payload.defaultTab) {
                manage.managemenetVisibleTab = MANAGE_ACTION_TAB_CLASS_ADD;
                manage.managementOtherData = null
            } else {
                manage.managemenetVisibleTab = action.payload.defaultTab;
                manage.managementOtherData = action.payload.otherData
            }
        },
        addEditContainerViewShown: (manage, action) => {
            if(!action.payload.defaultTab) {
                manage.managemenetVisibleTab = MANAGE_ACTION_TAB_CONTAINER_OR_PROBLEM_ADD;
                manage.managementOtherData = null
            } else {
                manage.managemenetVisibleTab = action.payload.defaultTab;
                manage.managementOtherData = action.payload.otherData
            }
        },
        addEditItemViewHidden: (manage, action) => {
            manage.managemenetVisibleTab = null
            manage.managementOtherData = null
        },
    }
});

export default slice.reducer;
export const { resetManage, addEditContainerViewShown, addNewUserToClassViewHidden, classStudentsManagementShown, addEditClassViewShown, addEditItemViewHidden } = slice.actions;

//Action creators

export const saveClassStudentsEdit = (students) => (dispatch, getState) => {

    const { items, user } = getState();

    if(!isTeacherUser(user)) {
        /**
         * Only teachers can load class students data for management
         */
        return;
    }

    const { path, selectedClassId } = items;
    const classContainer = path[path.length - 1];

    if(classContainer.type !== ITEM_TYPE_CLASS) {
        throw Error("Item type should be class to relaod students");
    }

    const reloadStudentsAction = getLoadStudentsAction(classContainer, NAVIGATION_TYPE_GOTO);


    return dispatch(apiCallBegan({
        url: `webapp/edit-class-students/${selectedClassId}`,
        method: 'post',
        data: { students },
        onError: [reloadStudentsAction, addNewUserToClassViewHidden.type],
        onFail: [reloadStudentsAction, addNewUserToClassViewHidden.type],
        onStart: slice.actions.resetManage.type,
        onSuccess: [reloadStudentsAction, addNewUserToClassViewHidden.type]
    }));
}

export const addEditClass = (data) => (dispatch, getState) => {
    const { user } = getState();
    if(!isTeacherUser(user)) {
        /**
         * Only teachers can load class students data for management
         */
        return;
    }

    const url = data.selectedClassId ? `webapp/manage/add-edit-class/${data.selectedClassId}` : 'webapp/manage/add-edit-class';

    const requestData = {
        name: data.className,
        automaticAnswer: data.classAutomaticAnswerFlag,
        backupTeacher: data.classBackupTeacher,
        grade: data.classGrade,
        students: Object.values(data.classStudents)
    };

    const reloadClassesAction = getLoadClassesAction();

    return dispatch(apiCallBegan({
        url,
        method: 'post',
        data: requestData ,
        onError: [reloadClassesAction, addNewUserToClassViewHidden.type],
        onFail: [reloadClassesAction, addNewUserToClassViewHidden.type],
        onSuccess: [reloadClassesAction, addNewUserToClassViewHidden.type]
    }));
}


export const addEditContainer = (data) => (dispatch, getState) => {
    const { items, user } = getState();
    if(!isTeacherUser(user)) {
        /**
         * Only teachers can load container students data for management
         */
        return;
    }

    let url = '';
    if(data.selectedItem && data.selectedItem.id) {
        url = `webapp/manage/add-edit-container/${items.selectedClassId}/null/${data.selectedItem.id}`;
    } else if(getCurrentNavigationContainerType(items) === ITEM_TYPE_CLASS) {
        url = `webapp/manage/add-edit-container/${items.selectedClassId}`;
    } else {
        const parentContainer = getPathLastItem(items).id;
        url = `webapp/manage/add-edit-container/${items.selectedClassId}/${parentContainer}`;
    }

    const requestData = {
        ...data.selectedItem
    };

    const reloadContainersAction = getLoadContainersAction(getPathLastItem(items));

    return dispatch(apiCallBegan({
        url,
        method: 'post',
        data: requestData ,
        onError: [reloadContainersAction, addNewUserToClassViewHidden.type],
        onFail: [reloadContainersAction, addNewUserToClassViewHidden.type],
        onSuccess: [reloadContainersAction, addNewUserToClassViewHidden.type]
    }));
}

export const addEditProblem = (data) => async (dispatch, getState) => {
    const { items, user } = getState();
    if(!isTeacherUser(user)) {
        /**
         * Only teachers can load container students data for management
         */
        return;
    }

    let url = '';
    if(data.selectedItem && data.selectedItem.id) {
        url = `webapp/manage/add-edit-problem/${data.selectedItem.id}`;
    } else {
        const selectedContainerId = getPathLastItem(items).id;
        url = `webapp/manage/add-edit-problem/null/${selectedContainerId}`;
    }

    const formData = new FormData();
    formData.append('image', data.selectedItem.image);
    formData.append('name', data.selectedItem.name);
    formData.append('description', data.selectedItem.description);
    formData.append('status', data.selectedItem.status);
    formData.append('answer_type', data.selectedItem.answer_type);
    formData.append('answers_count', data.selectedItem.answers_count);
    formData.append('right_answer', data.selectedItem.right_answer);
    formData.append('choice_answer_show_type', data.selectedItem.choice_answer_show_type);
    formData.append('problem_type', data.selectedItem.problem_type);
    formData.append('solution', data.selectedItem.solution);

    const reloadContainersAction = getLoadContainersAction(getPathLastContainerItem(items));

    try {
        await request(url, 'POST', formData);
    } catch (ex) {
        console.log("Invalid response")
    }

    dispatch(reloadContainersAction);
    dispatch(addEditItemViewHidden());
}

export const cloneClass = (classId) => {

    const reloadClassesAction = getLoadClassesAction();
    
    return apiCallBegan({
        url: `webapp/manage/clone-class/${classId}`,
        method: 'post',
        onSuccess: [
            reloadClassesAction,
            hideMessage.type
        ],
        onFail: reloadClassesAction,
        onError: reloadClassesAction
    });
}

export const removeClass = (classId) => {

    const reloadClassesAction = getLoadClassesAction();
    
    return apiCallBegan({
        url: `webapp/manage/remove-class/${classId}`,
        method: 'post',
        onSuccess: [
            reloadClassesAction,
            hideMessage.type
        ],
        onFail: reloadClassesAction,
        onError: reloadClassesAction
    });
}


export const cloneContainer = (items, containerId) => {

    const reloadContainersAction = getLoadContainersAction(getPathLastItem(items));
    
    return apiCallBegan({
        url: `webapp/manage/clone-container/${containerId}`,
        method: 'post',
        onSuccess: [
            addEditItemViewHidden.type,
            reloadContainersAction,
            hideMessage.type
        ],
        onFail: reloadContainersAction,
        onError: reloadContainersAction
    });
}

export const removeContainer = (items, containerId) => {

    const reloadContainersAction = getLoadContainersAction(getPathLastItem(items));
    
    return apiCallBegan({
        url: `webapp/manage/remove-container/${containerId}`,
        method: 'post',
        onSuccess: [
            addEditItemViewHidden.type,
            reloadContainersAction,
            hideMessage.type,
        ],
        onFail: reloadContainersAction,
        onError: reloadContainersAction
    });
}

export const removePeoblemFromContainer = (items, problemId) => {

    const lastContainer = getPathLastContainerItem(items);
    const reloadContainersAction = getLoadContainersAction(lastContainer);
    
    return apiCallBegan({
        url: `webapp/manage/remove-problem-from-container/${problemId}`,
        method: 'post',
        onSuccess: [
            addEditItemViewHidden.type,
            reloadContainersAction,
            hideMessage.type,
        ],
        onFail: reloadContainersAction,
        onError: reloadContainersAction
    });
}

export const deleteSolution = (items, problemId) => {
    const reloadContainersAction = getLoadContainersAction(getPathLastContainerItem(items));
    
    return apiCallBegan({
        url: `webapp/manage/remove-solution/${problemId}`,
        method: 'post',
        onSuccess: [
            addEditItemViewHidden.type,
            reloadContainersAction,
            hideMessage.type,
        ],
        onFail: reloadContainersAction,
        onError: reloadContainersAction
    });
} 
