import {css} from "styled-components";

export const UnselectableCss = css`
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
`;

export const CursorPointerCss = css`
    cursor: pointer;
    &:focus {
        border: 0;
        outline: none;
    }
`;
