import { Alert, Button, Form, Tab, Tabs } from "react-bootstrap";
import { saveClassStudentsEdit } from "../lib/store/manage";
import { reloadLastNavigation } from "../lib/store/items";
import { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { request } from "../lib/request";
import Select from 'react-select'
import ClassStudents from "./classStudents";
import { applyMultiOnClassStudents, loadClassAndSchoolStudents } from "../helper/helpers";

const ManageStudentsContainer = styled.div`
    margin:30px auto;
    width:500px;

    .multi-wrapper .header {
        position: absolute;
        top: 45px;
    }

    .non-selected-wrapper, .selected-wrapper {
        margin-top:40px;
        
    }
    
    .multi-wrapper {
        position: relative;
    }

    .multi-wrapper .non-selected-wrapper, .multi-wrapper .selected-wrapper {
        height: 300px !important;
    }

    .btn-container {
        text-align: center;
        margin-top: 20px;
    }

    .create-new-student {
        margin-top: 20px;
    }
`;


const AddEditUserFormRenderer = (actionType, onSubmit, dispatchAddEditUserData, addEditUserData, currentClassName) => {
    const errorMessage = addEditUserData.errorMessage;

    let addUserToClassCheckbox = (<></>);
    let passwordRequired =false;
    
    if(actionType === 'new') {
        const addClassCheckboxText = `Add new created user to "${currentClassName}" class`;
        addUserToClassCheckbox = (
            <Form.Group controlId="validationCustom01">
                <Form.Check type="checkbox"
                checked={addEditUserData.userAddToClassValue}
                onChange={(event) => dispatchAddEditUserData({type: 'userAddToClassValue', payload: event.target.checked})}
                label={addClassCheckboxText} />
            </Form.Group>
        );
        passwordRequired = true
    }
    
    return (
        <div className="create-new-student">
            {errorMessage ? 
                (<Alert key={"error"} variant={"danger"}>
                    {errorMessage}
                </Alert>) : (<></>)}
            <Form key="Form" noValidate validated={addEditUserData.validated} onSubmit={(event) => onSubmit(event, actionType)}>
                <Form.Group key={`${actionType}_username`} controlId={`${actionType}_username`}>
                    <Form.Label>Username</Form.Label>
                    <Form.Control type="text" name={"n_username"}
                    disabled={actionType === 'edit'}
                    key={`${actionType}_abc`}
                    required
                    value={addEditUserData.username} 
                    onChange={(event) => dispatchAddEditUserData({type: 'username', payload: event.target.value})} 
                    placeholder="Enter username" />
                </Form.Group>
                <Form.Group controlId={`${actionType}_firstname`}>
                    <Form.Label>First name</Form.Label>
                    <Form.Control type="text" name={"n_firstname"}
                    value={addEditUserData.firstName} required
                    onChange={(event) => dispatchAddEditUserData({type: 'firstName', payload: event.target.value})} 
                    placeholder="Enter first name" />
                </Form.Group>
                <Form.Group controlId={`${actionType}_lastname`}>
                    <Form.Label>Last name</Form.Label>
                    <Form.Control type="text" name={"n_lastname"}
                    value={addEditUserData.lastName} required
                    onChange={(event) => dispatchAddEditUserData({type: 'lastName', payload: event.target.value})} 
                    placeholder="Enter last name" />
                </Form.Group>
                <Form.Group controlId={`${actionType}_password`}>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" name={"n_password"}
                    value={addEditUserData.password} required={passwordRequired}
                    onChange={(event) => dispatchAddEditUserData({type: 'password', payload: event.target.value})}
                    placeholder="Enter password" />
                </Form.Group>
                {addUserToClassCheckbox}
                <Button variant="primary" type="submit">
                    Save
                </Button>
            </Form>
        </div>
    )
}



const addEditStudentRequest = (event, type, dispatchAddEditUserData, addEditUserData, setEditStudentId, editStudentId, currentClassId, dispatch, items) => {
    const form = event.currentTarget;

    event.preventDefault();
    event.stopPropagation();

    dispatchAddEditUserData({type: 'validated', payload: true})

    if (form.checkValidity() === true) {
        const data = {...addEditUserData};

        let url = '/webapp/create-student';

        if(type === 'edit') {
            setEditStudentId(null);

            if(!editStudentId) {
                throw Error("Student id not set!!!")
            }

            url = `/webapp/edit-student/${editStudentId}`;
        } else {
            if(addEditUserData.userAddToClassValue && currentClassId) {
                data.attendClassId = currentClassId
            }
        }
        
        request(url, 'POST', data).then(response => {
            dispatch(reloadLastNavigation(items));
        }).catch(error => {
            dispatchAddEditUserData({type: 'errorMessage', payload: error.message})
        })
    }
}


            
const loadStudentForEdit = ({value: studentId}, setEditStudentId, dispatchAddEditUserData) => {
    setEditStudentId(null);
    request(`/webapp/load-student/${studentId}`, 'POST', {}).then(response => {
        dispatchAddEditUserData({type: 'reset', payload: { ...response, type: 'edit'}})
        setEditStudentId(studentId)
    }).catch(error => {
        setEditStudentId(0);
    })
}




const EditStudentsForm = (dispatchAddEditUserData, onSubmit, schoolStudents, editStudentId, addEditUserData, setEditStudentId, currentClassName) => {

    if(editStudentId === null) {
        return (<>Loading....</>)
    }

    const options = []
    for (const [key, value] of Object.entries(schoolStudents)) {
        options.push({label: value, value: key});
    }

    let editStudentFormHtml = (<></>);
    if(editStudentId) {
        // const onSubmit = event => addEditStudentRequest(event, "edit", dispatchAddEditUserData, addEditUserData, setEditStudentId, editStudentId, currentClassId, dispatch, items)
        editStudentFormHtml = AddEditUserFormRenderer("edit", onSubmit, dispatchAddEditUserData, addEditUserData, currentClassName)
    }

    const studentsSelector = (
        <Select options={options} name="language" value={editStudentId} onChange={value => loadStudentForEdit(value, setEditStudentId, dispatchAddEditUserData)} placeholder="Select students" />
    );
    
    return (<>
        {studentsSelector}
        {editStudentFormHtml}
    </>)
};


const ManageStudents = ({items, currentClassName, currentClassId}) => {

    const dispatch = useDispatch();

    const [classStudents, setClassStudents] = useState(null);
    const [schoolStudents, setSchoolStudents] = useState(null);
    const [editStudentId, setEditStudentId] = useState(0);


    const selectRef = useRef(null);

    useEffect(() => {
        if(selectRef.current) {
            applyMultiOnClassStudents(selectRef.current)
        }
    }, [schoolStudents]);

    useEffect(() => {
        loadClassAndSchoolStudents(setClassStudents, setSchoolStudents, items.selectedClassId)
        // eslint-disable-next-line
    }, []);


    const resetAddEditUserData = (initialState) => ({
        type: initialState.type,
        firstName: initialState.firstName ? initialState.firstName : '',
        lastName:  initialState.lastName ? initialState.lastName : '',
        username: initialState.username ? initialState.username : '',
        password: initialState.password ? initialState.password : '',
        validated: false,
        userAddToClassValue: initialState.userAddToClassValue ? initialState.userAddToClassValue : true,
        errorMessage: '',
    }) 

    const [addEditUserData, dispatchAddEditUserData] = useReducer((state, action) => {
        if('type' === action.type) {
            return { ...state, type: action.payload }
        } else if('reset' === action.type) {
            return resetAddEditUserData(action.payload)
        } else if('userAddToClassValue' === action.type) {
            if(state.type === 'edit') {
                throw Error("Can not attach to class for existing user edit")
            }
            return { ...state, userAddToClassValue: action.payload }
        } else if(['validated', 'errorMessage', 'firstName', 'lastName', 'username', 'password'].includes(action.type)) {
            return { ...state, [action.type]: action.payload}
        }
    }, {type: 'new'}, resetAddEditUserData)


    if(schoolStudents === null) {
        return (<>Loading.....</>);
    }


    const cleanupStudentEdit = () => {
        setEditStudentId(0);
        dispatchAddEditUserData({type: 'reset', payload: {type: 'new'}})
    }

    const studentsAddEditOnSubmit = (event, actionType) => addEditStudentRequest(event, actionType, dispatchAddEditUserData, addEditUserData, setEditStudentId, editStudentId, currentClassId, dispatch, items)


    const saveClassStudentsHandler = () => {
        cleanupStudentEdit();
        dispatch(saveClassStudentsEdit(Array.from(selectRef.current.selectedOptions, option => option.value)))
    }
    
    return (
        <ManageStudentsContainer>
            <Tabs transition={false} onSelect={(key) => key !== 'selectExisting' ? cleanupStudentEdit() : false} 
            defaultActiveKey="selectExisting">
                <Tab eventKey="selectExisting" title="Class students">

                    { ClassStudents(selectRef, schoolStudents, classStudents) }

                    <div key={"submit"} className={"btn-container"}>
                        <Button onClick={ saveClassStudentsHandler }>Save</Button>
                    </div>
                    
                </Tab>
                <Tab eventKey="addNew" title="Create new student">
                    {AddEditUserFormRenderer("new", studentsAddEditOnSubmit, dispatchAddEditUserData, addEditUserData, currentClassName)}
                </Tab>
                <Tab eventKey="editStudent" title="Edit students">
                    {EditStudentsForm(dispatchAddEditUserData, studentsAddEditOnSubmit, schoolStudents, editStudentId, addEditUserData, setEditStudentId, currentClassName)}
                </Tab>
            </Tabs>
        </ManageStudentsContainer>
    );
}

export default ManageStudents;