import { combineReducers } from '@reduxjs/toolkit';
import authReducer from './user';
import containersNavigatorReducer from './items';
import work from './work';
import messageReducer from './message';
import manageReducer from './manage';

export default combineReducers({
    user: authReducer,
    items: containersNavigatorReducer,
    work: work,
    message: messageReducer,
    manage: manageReducer,
});