import NavigationBlock from '../components/navigationBlock';
import ContentBlock from '../components/contentBlock';
import HeaderBlock from '../components/headerBlock'
import styled from 'styled-components';

const Main = styled.div`
    display:flex;
    flex-direction: row;
    height: calc(100% - 50px);
`;

const LoggedInComponent = (props) => {
    return (
        <div className="container">
            <div className="main-container">
                <HeaderBlock key={"HeaderBlock"} />
                <Main key={"Main"}>
                    <NavigationBlock />
                    <ContentBlock />
                    
                </Main>
            </div>
        </div>
    );
}

export default LoggedInComponent;